import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Avatar,
  Box,
  CircularProgress,
  Divider,
  Grid,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import { useDispatch } from '../store'
import { PrivatePage } from '../component/PagePrivate'
import { useParams } from 'react-router-dom'
import { FaceSearchIdItem, SearchResult } from '../store/searcher/reducer'
import { fetchResultById } from '../store/searcher/actionCreator'
import { NameCommon } from '../container/result/NameCommon'
import { DeepWebCommon } from '../container/result/DeepWebCommon'
import { PhoneCommon } from '../container/result/PhoneCommon'
import { ResultCriteria, ResultCriteriaTitle, ResultName, ResultNameTitle, ResultNotVerified, ResultTitle, ResultVerified } from '../style/result'
import { PostCommon } from '../container/result/PostCommon'
import { Image, prepareCommonResult, PreparedCommonResult } from '../store/searcher/service'
import sprite_ from '../sass/sprite.module.sass'
import { getTitle } from '../function/string'
import { RenderImage } from '../component/searcher/RenderData'
import { Masonry } from '@mui/lab'
import { SentimentAnalysisCommon } from '../container/result/SentimentAnalysisCommon'
import { ImageCommon } from '../container/result/ImageCommon'
import { IPGeolocationCommon } from '../container/result/IPGeolocationCommon'
import { KYCCommon } from '../container/result/KYCCommon'
import { WebCollectionCommon } from '../container/result/WebCollectionCommon'
import { JsonCommon } from '../container/result/JsonCommon'
import { IpPhoneGeolocationCommon } from '../container/result/IpPhoneGeolocationCommon'
import { LinkPreview } from '../component/searcher/LinkPreview'
import { PsychoProfileCommon } from '../container/result/PsychoProfileCommon'

export function ResultPage() {
  //states
  const { t } = useTranslation()
  const { id } = useParams() as {
    id: string;
  }
  const dispatch = useDispatch()

  const [loading, setLoading] = React.useState<boolean>(false)
  const [result, setResult] = React.useState<SearchResult>({} as SearchResult)
  const [viewData, setViewData] = React.useState<PreparedCommonResult>()
  const [compareImage, setCompareImage] = useState<FaceSearchIdItem>()
  const [activeImage, setActiveImage] = React.useState<Image>()
  const [isMobile, setIsMobile] = React.useState<Boolean>(false)
  const theme = useTheme()
  //callbacks
  const dispatchSingleResult = React.useCallback((id: string) => {
    setLoading(true)
    dispatch(fetchResultById(id, (data: SearchResult) => {
      
      setResult(data)
      const vd = prepareCommonResult(data)
      setViewData(vd)
          
      if (vd?.images?.length) {
        setActiveImage(vd.images[0])
      }

      setLoading(false)
    }))
  }, [dispatch])

  //effects
  React.useEffect(() => {
    dispatchSingleResult(String(id))
  }, [id, dispatchSingleResult])

  //handlers

  const handleImageClick = (el: any, image: Image) => {
    el.preventDefault()
    setActiveImage(image)
  }

  const handleCompareImageClick = (cImage: any) => {
    setCompareImage(cImage)
  }

  console.log('fetchResultById', result)
  console.log('viewData', viewData)  
  return (
    <PrivatePage>
      {
        loading && <CircularProgress />
      }
      
      <Typography fontSize={16} color={'#747474'} sx={{}}>
      <Stack direction={'row'} alignItems={'center'} justifyContent={'center'}>
          <Typography fontSize={16} color={'#747474'} sx={{}}>
            {result?.type && ['phone', 'combined_phone'].includes(result.type) && 'Phone lookup results'}
            {result?.type && ['name', 'combined_name', 'combined_id'].includes(result.type) && 'Social networks profiles'}
            {result?.type && ['combined_email'].includes(result.type) && 'Search by email'}
            {result?.type === 'post' && 'Posts observer results'}
            {result?.type === 'deepweb' && 'Pluto lookup results'}
            {result?.type === 'face_search' && 'Face seach results'}
            {result?.type === 'sentiment_analysys' && 'Sentiment Analysys'}
            {result?.type === 'irbis_eye' && 'Irbis eye results'}
            {result?.type === 'web_data_search' && 'Facebook API'}
            {result?.type === 'fb_person_wall_keyword' && 'Facebook API Keywords'}
            {result?.type === 'kyc' && 'KYC'}
            {result?.type === 'ip_geo' && 'IP Geolocation'}
            {result?.type === 'ipgeo_number' && 'Geolocation by IP & Phone'}
            {result?.type === 'web_collection' && 'WEB Collection'}
            {result?.type === 'name_by_country' && 'Name by Country'}
            {result?.type === 'photo_searcher' && 'Sniper API results'}
            {result?.type === 'psycho_profile' && 'Psychological Profile Analysis'}
            {result?.type === 'psycho_profile_summary' && 'Psychological Profile Analysis'}
            
          </Typography>
          {
            result?.type !== 'sentiment_analysys' && <ResultCriteria>
              <Box
                className={sprite_[`social__small_${result?.type !== 'deepweb' ? result?.type : viewData?.deepwebType}`]}
                sx={{marginRight: '10px'}}
              />
              {
                ['face_search', 'irbis_eye', 'photo_searcher'].includes(result?.type + '')
                  ? <img
                    src={result?.criteria}
                    alt=""
                    width="40"
                    height="50"
                    style={{ objectFit: 'cover' }}
                  />
                  : <Tooltip title={result?.criteria || ''} placement="bottom">
                    <ResultCriteriaTitle>{result?.criteria}</ResultCriteriaTitle>
                  </Tooltip>
              }
            </ResultCriteria>
          }
          {
                (result?.type === 'combined_phone' && result.status === 'finished' && viewData?.isVerifiedName === true) && 
                <Box sx={{flexShrink: 0, m: 0, p: 2 } }>
                { 
                (viewData?.verifiedScore && viewData?.verifiedScore > 0.7 ) ?
                <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} color="green">
                  {`${viewData?.finalClassification} (${ Math.round( (viewData?.verifiedScore as number) * 100) }%)`}
                </Stack>
                :
                <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} color="red">
                  {`${viewData?.finalClassification} (${ Math.round( (viewData?.verifiedScore as number) * 100) }%)`}
                </Stack>
                }    
                </Box>
          }
        </Stack>
      </Typography>

      
      {viewData && <>
          <Stack direction={'row'} alignItems={'center'} justifyContent={'center'}>
          {
              viewData.foundSources?.length > 0 &&
              <Stack direction={'column'} alignItems={'center'} justifyContent={'center'}>
                <Stack direction={'row'} alignItems={'center'} justifyContent={'center'}>
                  {
                    viewData.foundSources.map((app, idx) =>
                      <Tooltip key={idx} title={getTitle(app) || ''} placement="top">
                        <Box key={idx} className={sprite_[`social__large_${app}`]} sx={{marginRight: '8px'}} />
                      </Tooltip>,
                    )
                  }
                </Stack>
                {/* {
                  viewData?.useTaro && <VerifiedServicesInline found={true} socialNetworks={viewData.verifiedServices} />
                } */}
              </Stack>
            }
          {
              viewData.foundSources?.length > 0 && viewData.notFoundSources?.length > 0 && <Divider
                sx={{
                  height: '50px',
                  borderColor: 'rgba(46, 151, 213, 0.5)',
                  margin: '0 10px',
                  [theme.breakpoints.only("xs")]: {
                    height: '20px',
                    margin: '10px 0',
                  },
                }}
                orientation={'vertical'}
              />
            }
            {
              viewData.notFoundSources?.length > 0 &&
              <Stack direction={'column'} alignItems={'center'} justifyContent={'center'}>
                <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} sx={{marginLeft: '20px', flexWrap: 'wrap'}}>
                  <Typography sx={{marginRight: '5px'}} fontSize={14} color={'#747474'}>Not found:</Typography>
                  {
                    viewData.notFoundSources.map((app, idx) =>
                      <Tooltip key={idx} title={getTitle(app) || ''} placement="top">
                        <Box key={idx} className={sprite_[`social__small_${app}`]} sx={{marginRight: '8px', opacity: '0.6'}} />
                      </Tooltip>,
                    )
                  }
                </Stack>
                {/* {
                  viewData?.useTaro && <VerifiedServicesInline found={true} socialNetworks={viewData.verifiedServices} />
                } */}
              </Stack>
            }
          </Stack>

          {
            ['face_search', 'irbis_eye', 'photo_searcher'].includes(result?.type || '') && <ImageCommon
              viewData={viewData}
              apiData={result}
              compareImage={compareImage}
              handleCompareImage={handleCompareImageClick}
            />
          }
          {
            !['face_search', 'irbis_eye', 'photo_searcher', 'sentiment_analysys', 'ip_geo', 'ipgeo_number', 'psycho_profile', 'psycho_profile_summary', 'kyc', 'web_collection'].includes(result?.type || '') && <Stack sx={{padding: '20px'}}>
              <Grid container spacing={2}>
                {
                  activeImage && <Grid item xs={'auto'}>
                    <Box sx={{width: '220px', height: '250px'}}>
                      <RenderImage
                        _key={'activeImage'}
                        val={activeImage.image}
                        imgHeight={'250px'}
                      />
                    </Box>
                  </Grid>
                }
                <Grid item xs>
                  <Stack direction={'column'} alignItems={'space-between'} justifyContent={'space-between'} sx={{height: '100%', width: '100%'}}>
                    {
                      viewData.images.length > 0 && <Stack direction={'row'} flexWrap={'wrap'} rowGap={1}>
                        {
                          viewData.images.map((image, idx) => <Box key={idx} sx={{width: '67px', height: '75px', marginRight: '10px', position: 'relative'}}>
                            <RenderImage
                              _key={idx + ''}
                              val={image.image}
                              imgHeight={'75px'}
                              onClick={(e) => handleImageClick(e, image)}
                            />
                            <Box className={sprite_[`social__small_${image.app}`]} sx={{marginRight: '8px', width: '20px', position: 'absolute', top: '2px', left: '2px'}} />
                          </Box>)
                        }
                      </Stack>
                    }
                    <Stack direction={'column'} sx={{margin: '20px 0'}}>
                      <Stack
                        direction={'row'}
                        sx={{
                          [theme.breakpoints.only("xs")]: {
                            flexDirection: 'column',
                          },
                        }}
                      >
                        <ResultName>
                          <Tooltip title={viewData.name || ''} placement="bottom">
                            <ResultNameTitle>
                              {viewData.name}
                              {
                                viewData?.criteriaCountry && <img
                                  loading='lazy'
                                  width='20'
                                  height='15'
                                  src={`https://flagcdn.com/w20/${viewData.criteriaCountry.toLowerCase()}.png`}
                                  srcSet={`https://flagcdn.com/w40/${viewData.criteriaCountry.toLowerCase()}.png 2x`}
                                  alt=''
                                />
                              }
                            </ResultNameTitle>
                          </Tooltip>
                        </ResultName>
                        {
                          viewData.foundSources.length > 0
                            ? <ResultVerified>Verified 100%</ResultVerified>
                            : <ResultNotVerified>Results not found</ResultNotVerified>
                        }
                      </Stack>
                      {
                        viewData.createdDate && <Stack direction={'row'} sx={{marginTop: '10px'}}>
                          <Typography fontSize={16} color={'#214A98'} fontWeight={700} sx={{marginRight: '10px'}}>Retrieved:</Typography>
                          <Typography fontSize={16} color={'#747474'} fontWeight={400}>{viewData.createdDate}</Typography>
                        </Stack>
                      }
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>
            </Stack>
          }

          {
            !['sentiment_analysys'].includes(result?.type || '') && <Box sx={{ width: '100%', marginBottom: '30px' }}>
              <Masonry columns={isMobile ? 1 : 2} spacing={1}>
                {
                  viewData.names.length > 0 && <Box sx={{ height: 'auto', width: '100%' }}>
                    <Stack direction={'column'} sx={{margin: '10px 0'}}>
                      <ResultTitle>Names:</ResultTitle>
                      {
                        viewData.names.map((name, idx) => <Stack key={idx} direction={'row'} sx={{}} alignItems={'center'}>
                          {
                            name?.app && <Tooltip title={name?.app} placement="top">
                              <Box className={sprite_[`social__small_${name.app}`]} sx={{marginRight: '8px'}} />
                            </Tooltip>
                          }
                          <Typography fontSize={18} fontWeight={400} color={'#747474'} sx={{wordBreak: 'break-all'}}>{name.name}</Typography>
                        </Stack>)
                      }
                    </Stack>
                  </Box>
                }
                {
                  viewData.phones.length > 0 && <Box sx={{ height: 'auto', width: '100%' }}>
                    <Stack direction={'column'} sx={{margin: '10px 0'}}>
                      <ResultTitle>Phones:</ResultTitle>
                      {
                        viewData.phones.map((name, idx) => <Stack key={idx} direction={'row'} sx={{}} alignItems={'center'}>
                          {
                            name?.app && <Tooltip title={name?.app} placement="top">
                              <Box className={sprite_[`social__small_${name.app}`]} sx={{marginRight: '8px'}} />
                            </Tooltip>
                          }
                          <Typography fontSize={18} fontWeight={400} color={'#747474'} sx={{wordBreak: 'break-all'}}>{name.name}</Typography>
                        </Stack>)
                      }
                    </Stack>
                  </Box>
                }
                {
                  viewData.socialNetworks.length > 0 && <Box sx={{ height: 'auto', width: '100%' }}>
                    <Stack direction={'column'} sx={{margin: '10px 0', width: '100%'}}>
                      <ResultTitle>Social Networks:</ResultTitle>
                      {
                        viewData.socialNetworks.map((name, idx) => <Stack key={idx} direction={'row'} sx={{height: '83px', width: '100%'}} alignItems={'center'}>
                          <Box className={sprite_[`social__small_${name?.app}`]} sx={{marginRight: '8px', width: '20px'}} />
                          {
                            name.url
                              ? <LinkPreview title={name.socialNetwork} link={name.url} style={{height: 'fit-content', width: 'calc(100% - 28px)'}} defaultIcon={name?.app} />
                              : <Typography fontSize={18} fontWeight={400} color={'#747474'} sx={{wordBreak: 'break-all'}}>{name.socialNetwork}</Typography>
                          }
                        </Stack>)
                      }
                    </Stack>
                  </Box>
                }
                {/*{
                  viewData?.useTaro && <VerifiedServices socialNetworks={viewData.verifiedServices} />
                }*/}
                {/*{
                viewData.lastOnline.length > 0 && <Box sx={{ height: 'auto', width: '100%' }}>
                  <Stack direction={'column'} sx={{margin: '10px 0'}}>
                    <ResultTitle>Last Online:</ResultTitle>
                    {
                      viewData.lastOnline.map((name, idx) => <Stack key={idx} direction={'row'} sx={{}} alignItems={'center'}>
                        <Box className={sprite_[`social__small_${name.app}`]} sx={{marginRight: '8px'}} />
                        <Typography fontSize={18} fontWeight={400} color={'#747474'} sx={{wordBreak: 'break-all'}}>{name.online}</Typography>
                      </Stack>)
                    }
                  </Stack>
                </Box>
              }*/}
                {
                  viewData.birthday.length > 0 && <Box sx={{ height: 'auto', width: '100%' }}>
                    <Stack direction={'column'} sx={{margin: '10px 0'}}>
                      <ResultTitle>Birthday:</ResultTitle>
                      {
                        viewData.birthday.map((name, idx) => <Stack key={idx} direction={'row'} sx={{}} alignItems={'center'}>
                          <Box className={sprite_[`social__small_${name.app}`]} sx={{marginRight: '8px'}} />
                          <Typography fontSize={18} fontWeight={400} color={'#747474'} sx={{wordBreak: 'break-all'}}>{name.birthday}</Typography>
                        </Stack>)
                      }
                    </Stack>
                  </Box>
                }
                {
                  viewData.emails.length > 0 && <Box sx={{ height: 'auto', width: '100%' }}>
                    <Stack direction={'column'} sx={{margin: '10px 0'}}>
                      <ResultTitle>Emails:</ResultTitle>
                      {
                        viewData.emails.map((name, idx) => <Stack key={idx} direction={'row'} sx={{}} alignItems={'center'}>
                          <Box className={sprite_[`social__small_${name.app}`]} sx={{marginRight: '8px'}} />
                          <Typography fontSize={18} fontWeight={400} color={'#747474'} sx={{wordBreak: 'break-all'}}>{name.email}</Typography>
                        </Stack>)
                      }
                    </Stack>
                  </Box>
                }
                {
                  viewData.urls.length > 0 && <Box sx={{ height: 'auto', width: '100%' }}>
                    <Stack direction={'column'} sx={{margin: '10px 0'}}>
                      <ResultTitle>Web Sites:</ResultTitle>
                      {
                        viewData.urls.map((url, idx) => <Stack key={idx} direction={'row'} sx={{}} alignItems={'center'}>
                          <a href={url.link} target={'_blank'}>
                            <Typography fontSize={18} fontWeight={400} color={'#747474'} sx={{wordBreak: 'break-all'}}>{url.title}</Typography>
                          </a>
                        </Stack>)
                      }
                    </Stack>
                  </Box>
                }
                {
                  viewData.addresses.length > 0 && <Box sx={{ height: 'auto', width: '100%' }}>
                    <Stack direction={'column'} sx={{margin: '10px 0'}}>
                      <ResultTitle>Addresses:</ResultTitle>
                      {
                        viewData.addresses.map((name, idx) => <Stack key={idx} direction={'row'} sx={{}} alignItems={'center'}>
                          <Box className={sprite_[`social__small_${name.app}`]} sx={{marginRight: '8px'}} />
                          <Typography fontSize={18} fontWeight={400} color={'#747474'} sx={{wordBreak: 'break-all'}}>{name.address}</Typography>
                        </Stack>)
                      }
                    </Stack>
                  </Box>
                }
              </Masonry>
            </Box>
          }

          {
            result && ['phone', 'combined_phone', 'combined_email'].includes(result.type) && <>
              <ResultTitle>Details:</ResultTitle>
              <PhoneCommon apiData={result} />
            </>
          }

          {
            result
              && ['name', 'combined_name', 'combined_id'].includes(result.type)
              && <NameCommon apiData={result} />
          }

          {
            result && result.type === 'post' && <>
              <ResultTitle>Posts:</ResultTitle>
              <PostCommon apiData={result} />
            </>
          }

          {
            result
              && ['deepweb', 'combined_email', 'combined_name', 'combined_phone', 'combined_id', 'name_by_country'].includes(result.type)
              && <DeepWebCommon apiData={result} />
          }

          {
            result
              && ['sentiment_analysys'].includes(result.type)
              && <SentimentAnalysisCommon apiData={result} />
          }
          {
            result
              && ['kyc'].includes(result.type)
              && <KYCCommon key={'kyc'} apiData={result} />
          }
          {
            result
              && ['ip_geo'].includes(result.type)
              && <IPGeolocationCommon apiData={result} />
          }
          {
            result
              && ['ipgeo_number'].includes(result.type)
              && <IpPhoneGeolocationCommon apiData={result} />
          }
          {
            result
              && ['web_collection'].includes(result.type)
              && <WebCollectionCommon apiData={result} />
          }
          {
            result
              && ['psycho_profile'].includes(result.type)
              && <PsychoProfileCommon apiData={result} />
          }
          {
            result
              && ['psycho_profile_summary'].includes(result.type)
              && <PsychoProfileCommon apiData={result} />
          }
          {
            result
            && ['web_data_search', 'fb_person_wall_keyword', 'api_v2_x', 'api_v2_instagram', 'api_v2_linkedin'].includes(result.type)
            && <JsonCommon apiData={result} />
          }
        </>
      }

    </PrivatePage>
  )
}
