import * as React from 'react'
import Joi, { Schema } from 'joi'
import { t } from 'i18next'
import {
  Close as CloseIcon,
  Search,
  ExpandMore as SelectIcon,
} from '@mui/icons-material'
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  IconButton,
  Typography,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Stack,
  Grid,
  ListSubheader,
  TextField,
} from '@mui/material'
import { Stepper } from '../Stepper'
import { useDispatch, useSelector } from '../../store'
import { FormError } from '../FormError'
import { InputRadio } from '../InputRadio'
import { InputCheckbox } from '../InputCheckbox'
import {
  FormState,
  latinOnly,
  validateForm,
  ErrorsState,
  preventCyrillicOnInput,
} from '../../function/validation'
import { PreviewPackage } from '../../container/package/PreviewPackage'
import {
  checkPackageData,
  postPackage,
  putPackage,
} from '../../store/package/actionCreator'
import {
  IFeature,
  Workflow,
  Package,
  PackageType,
  SubtitleType,
  Duration,
} from '../../store/package/reducer'
import { usePackagePopup } from '../../hook/usePackagePopup'
import { PopupActions } from './PopupActions'
import { TableRef } from '../datatable/types'
import { Feature } from './Feature'
import { PopupAsk } from '../PopupAsk'
import { clearFieldError } from '../../store/common/actionCreator'

const optionsDuration: Array<{ id: Duration; label: string }> = [
  { id: 1, label: '1 day' },
  { id: 5, label: '5 days' },
  { id: 15, label: '15 days' },
  { id: 30, label: '1 month' },
  { id: 90, label: '3 months' },
  { id: 180, label: '6 months' },
  { id: 365, label: '12 months' },
]

const schemaIsWebintUpgrade = Joi.any().when('type', {
  is: 'webinthub_upgrade',
  then: Joi.allow(null),
  otherwise: Joi.array().required().min(1),
})

const schemaIsFreeProductId = Joi.any().when('isFree', {
  is: true,
  then: Joi.allow(null),
  otherwise: Joi.number().required().min(10000).max(99999),
})
const schemaBase: Schema = Joi.object({
  type: Joi.allow('general', 'personal', 'custom', 'webinthub', 'telegrambt', 'webinthub_subpackage', 'demo', 'webinthub_demo', 'one_time_fee'),
  productId: Joi.any()
    .label(t('field.label.productId'))
    .when('type', {
      is: 'custom',
      then: Joi.allow(null),
      otherwise: schemaIsFreeProductId,
    })
    .concat(schemaIsFreeProductId),
  uid: Joi.string()
    .label(t('field.label.uid'))
    .when('type', {
      is: 'personal',
      then: Joi.string().alphanum().required().min(28).max(28),
    }).concat(
      Joi.string().when('type', {
        is: 'webinthub_demo',
        then: Joi.string().alphanum().required().min(28).max(28),
        otherwise: Joi.allow(null),
      }),
    ),
  apiRequestsLimits: Joi.number()
    .label(t('field.label.APIlimits'))
    .when('type', {
      is: 'personal',
      then: Joi.number(),
      otherwise: Joi.allow(null),
    }),
  apiRequestsInterval: Joi.number()
    .label(t('field.label.APIinterval'))
    .when('type', {
      is: 'personal',
      then: Joi.number(),
      otherwise: Joi.allow(null),
    }),
  title: latinOnly.required().label(t('field.label.title')),
  subTitleType: Joi.allow('text', 'status'),
  subTitleContext: Joi.string()
    .label(t('field.label.subTitle'))
    .when('subTitleType', {
      is: 'text',
      then: latinOnly,
      otherwise: latinOnly,
    }),
  lookupIds: Joi.any()
    .label(t('field.label.searchWorkflow'))
    .when('type', {
      is: 'custom',
      then: Joi.allow(null),
      otherwise: schemaIsWebintUpgrade,
    }),

  price: Joi.number()
    .required()
    .label(t('field.label.price'))
    .when('type', {
      is: 'custom',
      then: Joi.allow(null),
      otherwise: Joi.number(),
    })
    .concat(
      Joi.number().when('isFree', {
        is: true,
        then: Joi.allow(0),
        otherwise: Joi.number(),
      }),
    ),

  bonus: Joi.number()
    .label(t('field.label.bonus'))
    .when('type', {
      is: 'custom',
      then: Joi.allow(null),
    }),
  
  creditsPerUnit: Joi.number().required()
    .label("Credits per price unit can not be empty")
    .when('type', {
      is: 'general',
      then: Joi.number(),
    })
    .concat(
      Joi.number()
      .when('type', {
        is: 'personal',
        then: Joi.number(),
        otherwise: Joi.allow(null),
      }),
    ),

  annualPrice: Joi.number()
    .when('isAnnual',{
      is: true,
      then: Joi.number().integer()
        .min(Joi.ref('price', {render: true}))
        .max(Joi.ref('price', {adjust: (priceValue) => priceValue * 12, render: true}))
        .required(),
    }),
  annualProductId: Joi.number().integer()
    .when('isAnnual', {
      is: true,
      then: Joi.number().integer().required(),
    }),
  isFree: Joi.boolean(),
  isAnnual: Joi.boolean(),
  isActive: Joi.boolean(),
  activeDay: Joi.number()
    .label(t('field.label.duration'))
    .when('type', {
      is: 'custom',
      then: Joi.allow(null),
    }).concat(
      Joi.number().when('type', {
        is: 'webinthub_demo',
        then: Joi.allow(null),
        otherwise: Joi.number().required(),
      }),
    ),
  button: Joi.string()
    .label(t('field.label.button'))
    .when('isFree', {
      is: true,
      then: Joi.allow(null, ''),
      otherwise: latinOnly.label(t('field.label.button')),
    }),
})
const schemaFeatures: Schema = Joi.object({
  features: Joi.array()
    .required()
    .items(
      Joi.object({
        title: latinOnly.label(t('field.label.title')),
        subTitle: latinOnly.label(t('field.label.subTitle')),
        info: latinOnly.allow(''),//.max(100).label(t('field.label.info')),
      }),
    ),
})

// const MONEY_LIMIT = 1000

type Props = {
  mode: 'create' | 'edit'
  onClose: () => void
  tableRef: React.RefObject<TableRef>
  data?: Package
}

export function PopupPackage({ mode, tableRef, onClose, data }: Props) {
    const dispatch = useDispatch()
  const { initLookups } = usePackagePopup()

  const { tables, fieldErrors } = useSelector((state) => state.common)

  const [activeStep, setActiveStep] = React.useState(0)

  const [featureTab, setFeatureTab] = React.useState(0)

  const [type, setType] = React.useState<PackageType>(data?.type || 'general')
  const [productId, setProductId] = React.useState<number | string>(
    data?.productId || '',
  )
  const [uid, setUid] = React.useState<string>(data?.uid || '')
  const [title, setTitle] = React.useState(data?.title || '')
  const [subTitleType, setSubTitleType] = React.useState<SubtitleType>(
    data?.subTitle?.type || 'text',
  )
  const [subTitleContext, setSubTitleContext] = React.useState<string>(
    data?.subTitle?.context || '',
  )
  const [lookups, setLookups] = React.useState<Workflow[]>(
    initLookups(tables.workflow?.list, data?.lookups),
  )
  const [apiRequestsLimits, setApiRequestsLimits] = React.useState<number>(data?.apiRequestsLimits || 1)
  const [apiRequestsInterval, setApiRequestsInterval] = React.useState<number>(data?.apiRequestsInterval || 1)

  const [_features, setFeatures] = React.useState<IFeature[]>(initFeatures)
  const [price, setPrice] = React.useState<number>(data?.price || 0)
  const [bonus, setBonus] = React.useState<number>(data?.bonus || 0)
  const [creditsPerUnit, setCreditsPerUnit] = React.useState<number>(data?.creditsPerUnit || 0)
  
  const [isFree, setIsFree] = React.useState(data?.isFree || false)
  const [isAnnual, setIsAnnaul] = React.useState( data?.isAnnual  || false)
  const [annualProductId, setAnnualProductId] = React.useState<number>(
    data?.annualProductId || 0,
  )
  const [annualPrice, setAnnualPrice] = React.useState<number>(data?.annualPrice || 0)
  const [isActive, setIsActive] = React.useState(data?.isActive)

  const [activeDay, setActiveDay] = React.useState<Duration | null>(
    data?.activeDay || null,
  )
  const [button, setButton] = React.useState<string>(data?.button || '')
  const [errors, setErrors] = React.useState<ErrorsState>(null)
  const [errorsFeatures, setErrorsFeatures] = React.useState<ErrorsState>(null)
  const [askSkipAnyway, setAskSkipAnyway] = React.useState(false)
  const [askSaveAnyway, setAskSaveAnyway] = React.useState(false)

  React.useEffect(() => {
    setErrors(null)
    dispatch(clearFieldError)
  }, [
    dispatch,
    type,
    productId,
    title,
    subTitleContext,
    _features,
    price,
    bonus,
    creditsPerUnit,
    isFree,
    isAnnual,
    activeDay,
    button,
    isActive,
    annualProductId,
    annualPrice,
  ])
 
  const isDemo = type === 'demo'
  const isCustom = type === 'custom'
  const isPersonal = type === 'personal'
  const isWebintHub = type === 'webinthub'
  const isWebintSubPackage = type === 'webinthub_subpackage'
  const isWebintHubUpgrade = type === 'webinthub_upgrade'
  const isWebintHubDemo = type === 'webinthub_demo'
  const isOneTimeFee = type === 'one_time_fee'
  const isGeneral = type === 'general'
  

  const useUID = isWebintHubDemo || isPersonal
  
  const alreadyHaveCustom = tables?.package?.list.some(
    (el: Package) => el.type === 'custom',
  )
  const alreadyHaveDemo = tables?.package?.list.some(
    (el: Package) => el.type === 'demo',
  )
  const notSelectedLookups: Workflow[] = React.useMemo(() => {
    const selectedIDs = lookups.map((el) => el.id)
    return (
      tables.workflow?.list.filter(
        (el: Workflow) => !selectedIDs.includes(el.id),
      ) || []
    )
  }, [lookups, tables.workflow?.list])

  const features = isCustom ? _features : [..._features.slice(0, 3)]
  const lookupIds = React.useMemo(() => {
    if (isCustom) return null
    return lookups.map((el) => el.id)
  }, [isCustom, lookups])

  const requestData: FormState = {
    type,
    subTitleType,
    lookupIds,
    isFree,
    isAnnual,
    activeDay,
    title: title.trim(),
    subTitleContext: subTitleContext.trim(),
    uid: useUID ? uid : null,
    apiRequestsLimits: isPersonal ? apiRequestsLimits : 0,
    apiRequestsInterval: isPersonal ? apiRequestsInterval : 0,
    productId: isCustom || isFree || isDemo ? null : productId,
    price: isCustom ? null : price,
    bonus: isCustom ? null : bonus,
    creditsPerUnit: isPersonal || isGeneral ? creditsPerUnit : null,
    button: isFree || isDemo ? null : button.trim(),
    annualPrice: isAnnual ? annualPrice : 0,
    annualProductId: isAnnual ? annualProductId : 0,
    isActive,
  }

  const [searchWorkflow, setSearchWorkflow] = React.useState("general")

  function initFeatures() {
    return [
      {
        title: data?.feature1?.title || '',
        subTitle: data?.feature1?.subTitle || '',
        info: data?.feature1?.info || '',
      },
      {
        title: data?.feature2?.title || '',
        subTitle: data?.feature2?.subTitle || '',
        info: data?.feature2?.info || '',
      },
      {
        title: data?.feature3?.title || '',
        subTitle: data?.feature3?.subTitle || '',
        info: data?.feature3?.info || '',
      },
      {
        title: data?.feature4?.title || '',
        subTitle: data?.feature4?.subTitle || '',
        info: data?.feature4?.info || '',
      },
    ]
  }

  function handleTypeChange(e: SelectChangeEvent) {
    const value = e.target.value as PackageType
    setType(value)
    setIsFree(false)
    setIsAnnaul(false)
  }

  function handleIsActiveChange(
    e: React.SyntheticEvent<Element, Event>,
    checked: boolean,
  ) {
    if (checked) {
      setIsActive(true)
    } else {
      setIsActive(false)
    }
  }

  function handleIsFreeChange(
    e: React.SyntheticEvent<Element, Event>,
    checked: boolean,
  ) {
    if (checked) {
      setPrice(0)
      setIsFree(true)
    } else {
      setPrice(1)
      setIsFree(false)
    }
  }

  function handleIsAnnualChange(
    e: React.SyntheticEvent<Element, Event>,
    checked: boolean,
  ) {
    console.log("isChecked = ",checked)
    if (checked) {
      setIsAnnaul(true)
      setAnnualPrice(0)
      setAnnualProductId(0)
    } else {
      setIsAnnaul(false)
      setAnnualPrice(1)
      setAnnualProductId(1)
    }
  }

  function handleLookupSelect(e: SelectChangeEvent<number>) {
    const id = e.target.value as number
    const selected: Workflow | undefined = notSelectedLookups.find(
      (el) => el.id === id,
    )

    if (selected) {
      setLookups((state) => [...state, selected])
    }
  }

  function handleLookupDelete(id: number) {
    setLookups((state) => {
      return state.filter((el) => el.id !== id)
    })
  }

  function handleFeatureChange(
    index: number,
    key: 'title' | 'subTitle' | 'info',
    value: string,
  ) {
    setFeatures((state) => {
      if (state === null) return state

      const newArr: IFeature[] = [...state]
      newArr[index][key] = value
      return newArr
    })
  }

  function handleNext() {
    console.log("Request data = ",requestData)
    const errorBase = validateForm(schemaBase, requestData)
    console.log("errorBase = ",errorBase)
    const errorFeatures = validateForm(schemaFeatures, { features }, true)
    if (errorFeatures) setErrorsFeatures(errorFeatures)
    else setErrorsFeatures(null)

    if (errorBase) {
      setErrors(errorBase)
      return
    }
    else setErrors(null)

    if (typeof productId === 'number' && mode === 'edit') {//&& mode === 'create') {
      dispatch(checkPackageData(productId, uid, apiRequestsLimits, apiRequestsInterval, continueCallback))
    } else {
      continueCallback()
    }

    function continueCallback() {
      if (errorFeatures) setAskSkipAnyway(true)
      else setActiveStep(1)
    }
  }

  function handleSkipAnyway() {
    setAskSkipAnyway(false)
    setActiveStep(1)
  }

  function handleSave() {
    const error = validateForm(schemaFeatures, { features })
    if (error) {
      setErrorsFeatures(error)
      setAskSaveAnyway(true)
      return
    }

    handleSubmit()
  }

  function handleFillFields() {
    setActiveStep(0)
    setAskSaveAnyway(false)
  }

  function handleSubmit() {
    requestData.subTitle = {
      type: requestData.subTitleType,
      context: requestData.subTitleContext,
    }
    features.forEach((el, idx) => (requestData['feature' + (idx + 1)] = el))

    delete requestData.subTitleContext
    delete requestData.subTitleType
    delete requestData.features

    function successCallback() {
      tableRef.current?.refresh()
      onClose()
    }

    if (mode === 'create') dispatch(postPackage(requestData, successCallback))
    else dispatch(putPackage(requestData, data?.id as number, successCallback))
  }

  const enabledAnual : boolean = isDemo || isWebintHubUpgrade || isWebintSubPackage || isCustom || isWebintHubDemo || isOneTimeFee
  

  return (
    <Dialog open={true} fullWidth onClose={onClose}>
      <DialogTitle sx={{ fontSize: 20 }}>
        {mode === 'create' && t('package.create.package')}
        {mode === 'edit' && `${t('package.edit.package')}: ${data?.title}`}

        <IconButton className="button__close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <Box my={2}>
        <Stepper
          steps={[t('package.steps.package'), t('package.steps.preview')]}
          activeStep={activeStep}
        />
      </Box>

      {activeStep === 0 ? (
        <Stack
          mb={3}
          spacing={2}
          sx={{ padding: 1, height: 530, overflowY: 'auto' }}
        >
          {/** Type */}
          <FormControl>
            <FormLabel>{t('field.label.type')}</FormLabel>
            <Select
              value={type}
              IconComponent={SelectIcon}
              disabled={mode === 'edit'}
              onChange={handleTypeChange}
            >
              <MenuItem value="general">General</MenuItem>
              <MenuItem value="personal">Personal</MenuItem>
              <MenuItem value="custom" disabled={alreadyHaveCustom}>Customized</MenuItem>
              <MenuItem value="webinthub">IrbisPro</MenuItem>
              <MenuItem value="telegrambt">Telegram BT</MenuItem>
              <MenuItem value="webinthub_subpackage">IrbisPro Subpackage</MenuItem>
              <MenuItem value="webinthub_upgrade">IrbisPro Upgrade</MenuItem>
              <MenuItem value="demo" disabled={alreadyHaveDemo}>Demo (General)</MenuItem>
              <MenuItem value="webinthub_demo">Demo (IrbisPro Personal)</MenuItem>
              <MenuItem value="one_time_fee">One Time Fee Package</MenuItem>

            </Select>
          </FormControl>
          {/** Billing product ID */}
          {!isCustom && !isFree && !isDemo && (
            <FormControl fullWidth required>
              <FormLabel>{t('field.label.productId')}</FormLabel>
              <OutlinedInput
                type="number"
                value={productId}
                placeholder="12345"
                onChange={(e) => setProductId(Number(e.target.value))}
              //disabled={mode === 'edit'}
              />
              <FormError error={fieldErrors.productId || errors?.productId} />
            </FormControl>
          )}

          {/** UID */}
          {(isPersonal || isWebintHubDemo) && (
            <>
              <FormControl fullWidth required>
                <FormLabel>{t('field.label.uid')}</FormLabel>
                <OutlinedInput
                  value={uid}
                  placeholder="swQP7example70lxy1jM1world1"
                  onChange={(e) => setUid(e.target.value.trim())} />
                <FormError error={fieldErrors.uid || errors?.uid} />
                {isPersonal && (
                  <>
                    <FormLabel>{t('field.label.APIlimits')}</FormLabel>
                    <OutlinedInput
                      value={apiRequestsLimits}
                      onChange={(e) => setApiRequestsLimits(Number(e.target.value))}
                    />
                    <FormLabel>{t('field.label.APIinterval')}</FormLabel>
                    <OutlinedInput
                      value={apiRequestsInterval}
                      onChange={(e) => setApiRequestsInterval(Number(e.target.value))}
                    />
                  </>
                )}
              </FormControl>

            </>
          )}
          {/** Title */}
          <FormControl fullWidth required>
            <FormLabel>{t('field.label.title')}</FormLabel>
            <OutlinedInput
              value={title}
              placeholder={t('field.hint.title')}
              onChange={(e) => preventCyrillicOnInput(e, setTitle)}
              onBlur={() => {
                setTitle(title.trim())
              }}
            />
            <FormError error={errors?.title} />
          </FormControl>

          {/** Subtitle type & Subtitle context */}
          <FormControl>
            <FormLabel required>{t('package.subtitle')}</FormLabel>
            <RadioGroup
              row
              value={subTitleType}
              onChange={(e) => setSubTitleType(e.target.value as SubtitleType)}
              sx={{ marginBottom: '10px' }}
            >
              <FormControlLabel
                sx={{ height: 21, overflow: 'hidden' }}
                value="text"
                control={<InputRadio />}
                label={<Typography>{t('package.text')}</Typography>}
              />
              <FormControlLabel
                sx={{ height: 21, overflow: 'hidden' }}
                value="status"
                control={<InputRadio />}
                label={<Typography>{t('package.status')}</Typography>}
              />
            </RadioGroup>
            <OutlinedInput
              value={subTitleContext}
              placeholder={t('package.text')}
              onChange={(e) => setSubTitleContext(e.target.value)}
            />
            <FormError error={errors?.subTitleContext} />
          </FormControl>

          {/** Search workflow */}
          {(!isCustom && !isWebintHub && !isWebintSubPackage && !isWebintHubUpgrade && !isWebintHubDemo) && (
            <FormControl>
              <FormLabel required>{t('field.label.searchWorkflow')}</FormLabel>
              <Select
                MenuProps={{autoFocus: false}}
                IconComponent={SelectIcon}
                value={0}
                onChange={handleLookupSelect}
                onClose={() => setSearchWorkflow("")}
                sx={{ borderRadius: '5px 5px 0 0' }}
              >
                
                {<ListSubheader>
                  <TextField
                    size="small"
                    autoFocus
                    placeholder="Type to search"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search />
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                        setSearchWorkflow(e.target.value)
                      }}
                    onKeyDown={(e) => {
                      if (e.key !== "Escape"){
                        // Prevents autoselecting item while typing (default Select behaviour)
                        e.stopPropagation()
                      }
                    }}
                  />
                </ListSubheader>}
                <MenuItem value={0} disabled>
                  {t('field.hint.searchWorkflow')}
                </MenuItem>
                {notSelectedLookups
                .filter((option) => {return (option.title.toLowerCase().includes(searchWorkflow.toLowerCase()))})
                .map((el: Workflow) => (
                  (el.type !== "webint" && (
                  <MenuItem key={el.id} value={el.id}>
                    {el.title}
                  </MenuItem>
                  ))
                ))
                }
              </Select>

              {lookups?.length > 0 && (
                <Stack
                  direction="row"
                  flexWrap="wrap"
                  p={1}
                  pb={0}
                  bgcolor="#EFF0F6"
                >
                  {lookups?.map((el) => (
                    <Chip
                      key={el.id}
                      label={el.title}
                      sx={{ p: 0, mr: 1, mb: 1, background: el.color }}
                      onDelete={() => handleLookupDelete(el.id)}
                    />
                  ))}
                </Stack>
              )}
              <FormError error={errors?.lookupIds} />
            </FormControl>
          )}

          {isWebintSubPackage && (
            <FormControl>
            <FormLabel required>{t('field.label.searchWorkflow')}</FormLabel>
            <Select
              value={0}
              IconComponent={SelectIcon}
              onChange={handleLookupSelect}
              sx={{ borderRadius: '5px 5px 0 0' }}
            >
              <MenuItem value={0} disabled>
                {t('field.hint.searchWorkflow')}
              </MenuItem>
              {notSelectedLookups.map((el: Workflow) => (
                (el.type === "additional_quotas" && (
                <MenuItem key={el.id} value={el.id}>
                  {el.title}
                </MenuItem>
                ))
              ))
              }
            </Select>
            {lookups?.length > 0 && (
              <Stack
                direction="row"
                flexWrap="wrap"
                p={1}
                pb={0}
                bgcolor="#EFF0F6"
              >
                {lookups?.map((el) => (
                  <Chip
                    key={el.id}
                    label={el.title}
                    sx={{ p: 0, mr: 1, mb: 1, background: el.color }}
                    onDelete={() => handleLookupDelete(el.id)}
                  />
                ))}
              </Stack>
            )}
            <FormError error={errors?.lookupIds} />
          </FormControl>
          )}

          {(isWebintHub || isWebintHubDemo) && (
            <FormControl>
              <FormLabel required>{t('field.label.searchWorkflow')}</FormLabel>
              <Select
                value={0}
                IconComponent={SelectIcon}
                onChange={handleLookupSelect}
                sx={{ borderRadius: '5px 5px 0 0' }}
              >
                <MenuItem value={0} disabled>
                  {t('field.hint.searchWorkflow')}
                </MenuItem>
                {notSelectedLookups.map((el: Workflow) => (
                  ( el.type === "webint" && (
                  <MenuItem key={el.id} value={el.id}>
                    {el.title}
                  </MenuItem>
                  ))
                ))
                }
              </Select>
              {lookups?.length > 0 && (
                <Stack
                  direction="row"
                  flexWrap="wrap"
                  p={1}
                  pb={0}
                  bgcolor="#EFF0F6"
                >
                  {lookups?.map((el) => (
                    <Chip
                      key={el.id}
                      label={el.title}
                      sx={{ p: 0, mr: 1, mb: 1, background: el.color }}
                      onDelete={() => handleLookupDelete(el.id)}
                    />
                  ))}
                </Stack>
              )}
              <FormError error={errors?.lookupIds} />
            </FormControl>
          )}

          {/** Features */}
          <Stack>
            <Stack direction="row">
              <Button
                onClick={() => setFeatureTab(0)}
                color="secondary"
                sx={{ pl: 0 }}
              >
                <Typography
                  variant="bold"
                  color={
                    errors?.features0
                      ? 'error'
                      : featureTab === 0
                      ? 'black'
                      : 'secondary'
                  }
                >
                  {t('package.feature')} 1
                </Typography>
              </Button>
              <Button
                onClick={() => setFeatureTab(1)}
                color="secondary"
                sx={{ py: 0 }}
              >
                <Typography
                  variant="bold"
                  color={
                    errors?.features1
                      ? 'error'
                      : featureTab === 1
                      ? 'black'
                      : 'secondary'
                  }
                >
                  {t('package.feature')} 2
                </Typography>
              </Button>
              <Button onClick={() => setFeatureTab(2)} color="secondary">
                <Typography
                  variant="bold"
                  color={
                    errors?.features2
                      ? 'error'
                      : featureTab === 2
                      ? 'black'
                      : 'secondary'
                  }
                >
                  {t('package.feature')} 3
                </Typography>
              </Button>
              {isCustom && (
                <Button onClick={() => setFeatureTab(3)} color="secondary">
                  <Typography
                    variant="bold"
                    color={
                      errors?.features3
                        ? 'error'
                        : featureTab === 3
                        ? 'black'
                        : 'secondary'
                    }
                  >
                    {t('package.feature')} 4
                  </Typography>
                </Button>
              )}
            </Stack>
            {featureTab === 0 && (
              <Feature
                feature={_features[0]}
                index={0}
                onChange={handleFeatureChange}
                error={errorsFeatures?.features0}
              />
            )}
            {featureTab === 1 && (
              <Feature
                feature={_features[1]}
                index={1}
                onChange={handleFeatureChange}
                error={errorsFeatures?.features1}
              />
            )}
            {featureTab === 2 && (
              <Feature
                feature={_features[2]}
                index={2}
                onChange={handleFeatureChange}
                error={errorsFeatures?.features2}
              />
            )}
            {featureTab === 3 && (
              <Feature
                feature={_features[3]}
                index={3}
                onChange={handleFeatureChange}
                error={errorsFeatures?.features3}
              />
            )}
          </Stack>

          {/** Price & Bonus & IsFree */}
          {(!isCustom && !isWebintHubDemo) && (
            <>
              <Stack>
                <Stack direction="row" spacing={1}>
                  <FormControl fullWidth>
                    <FormLabel required>{t('field.label.price')}</FormLabel>
                    <OutlinedInput
                      type="number"
                      value={price}
                      startAdornment={
                        <InputAdornment position="start">€</InputAdornment>
                      }
                      onChange={(e) => setPrice(Number(e.target.value))}
                      readOnly={isFree}
                      inputProps={{
                        min: 0,
                        max: 999,
                      }}
                    />
                    <FormError error={errors?.price} />
                  </FormControl>
                  <FormControl fullWidth>
                    <FormLabel>{t('field.label.bonus')}</FormLabel>
                    <OutlinedInput
                      type="number"
                      value={bonus}
                      startAdornment={
                        <InputAdornment position="start">€</InputAdornment>
                      }
                      onChange={(e) => setBonus(Number(e.target.value))}
                      inputProps={{
                        min: 0,
                        max: 998,
                      }}
                    />
                    <FormError error={errors?.bonus} />
                  </FormControl>
                </Stack>
                <FormError error={errors?.moneySum} />
              </Stack>
              { (!isPersonal && !isOneTimeFee ) && (
                <FormGroup>
                  <FormControlLabel
                    sx={{ width: 90 }}
                    control={<InputCheckbox defaultChecked={isFree} />}
                    label={String(t('field.label.free'))}
                    onChange={handleIsFreeChange}
                    disabled={data?.isFree}
                  />
                </FormGroup>
              )}
            </>
          )}
                                  
          {(isGeneral || isPersonal) && (
            <FormControl fullWidth>
              <FormLabel required>{'Credits per price unit'}</FormLabel>
              <OutlinedInput
                type="number"
                value={creditsPerUnit}
                startAdornment={
                  <InputAdornment position="start">Credit</InputAdornment>
                }
                onChange={(e) => setCreditsPerUnit(Number(e.target.value))}
              />
              <FormError error={errors?.price} />
            </FormControl>
          )}

          {/** Duration */}
          {(!isCustom) && (
            <FormControl>
              <FormLabel required>{t('package.duration.title')}</FormLabel>
              <RadioGroup
                row
                value={activeDay}
                onChange={(e) =>
                  setActiveDay(Number(e.target.value) as Duration)
                }
                sx={{ marginBottom: '10px' }}
              >
                <Grid container flexWrap="wrap">
                  {optionsDuration.map((el) => (
                    <Grid key={el.id} item xs={4}>
                      <FormControlLabel
                        sx={{ height: 25, overflow: 'hidden' }}
                        value={el.id}
                        control={<InputRadio />}
                        label={<Typography>{el.label}</Typography>}
                      />
                    </Grid>
                  ))}
                </Grid>
              </RadioGroup>
              <FormError error={errors?.activeDay} />
            </FormControl>
          )}

          {(isCustom || !isFree && !isDemo) && (
            <Stack>
            <FormControl fullWidth>
              <FormLabel required>{t('field.label.button')}</FormLabel>
              <OutlinedInput
                value={button}
                placeholder={t('field.hint.text')}
                onChange={(e) => setButton(e.target.value)}
              />
              <FormError error={errors?.button} />
              </FormControl>
            </Stack>
          )}

          <Stack>
            <FormControl fullWidth>
              <FormControlLabel
                    control={<InputCheckbox defaultChecked={isAnnual} />}
                    label="Set annual price for this package"
                    onChange={handleIsAnnualChange}
                    disabled={ enabledAnual }
                  />
                    <FormLabel required>{t('field.label.annualPrice')}</FormLabel>
                  <OutlinedInput
                    type="number"
                    value={annualPrice}
                   startAdornment={
                  <InputAdornment position="start">€</InputAdornment>
                }
                onChange={(e) => setAnnualPrice(Number(e.target.value))}
                disabled={!isAnnual}
                inputProps={{
                  min: 0,
                  max: 999,
                }}
              />
              <FormError error={errors?.annualPrice}/>
              <FormLabel>{t('field.label.annualPackageId')}</FormLabel>
              <OutlinedInput
                type="number"
                value={annualProductId}
                startAdornment={
                  <InputAdornment position="start">ID:</InputAdornment>
                }
                onChange={(e) => setAnnualProductId(Number(e.target.value))}
                disabled={!isAnnual}
              />
            </FormControl>
          </Stack>

          <FormGroup>
          <FormControlLabel
          sx={{ width: 90 }}
          control={<InputCheckbox defaultChecked={isActive} />}
          label={"Active"}
          onChange={handleIsActiveChange}
          disabled={isDemo}
          />
          </FormGroup>
        </Stack>
      ) : (
        <PreviewPackage data={{ ...requestData, features }} lookups={lookups} />
      )}

      <PopupActions
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        handleNext={handleNext}
        handleSubmit={handleSave}
        onClose={onClose}
      />

      {askSkipAnyway && (
        <PopupAsk
          onClose={() => setAskSkipAnyway(false)}
          header={
            <Typography variant="bold" fontSize={18}>
              Some fields aren't filled
            </Typography>
          }
          actions={
            <>
              <Button variant="outlined" onClick={handleSkipAnyway}>
                Skip anyway
              </Button>
              <Button
                variant="contained"
                onClick={() => setAskSkipAnyway(false)}
              >
                Back to form
              </Button>
            </>
          }
        >
          <Stack spacing={1}>
            {errorsFeatures?.features0 && (
              <Box>
                <Typography variant="semiBold">Feature 1:</Typography>
                <FormError error={errorsFeatures?.features0} />
              </Box>
            )}
            {errorsFeatures?.features1 && (
              <Box>
                <Typography variant="semiBold">Feature 2:</Typography>
                <FormError error={errorsFeatures?.features1} />
              </Box>
            )}
            {errorsFeatures?.features2 && (
              <Box>
                <Typography variant="semiBold">Feature 3:</Typography>
                <FormError error={errorsFeatures?.features2} />
              </Box>
            )}
            {errorsFeatures?.features3 && (
              <Box>
                <Typography variant="semiBold">Feature 4:</Typography>
                <FormError error={errorsFeatures?.features3} />
              </Box>
            )}
          </Stack>

          <Typography variant="bold" color="inherit" mt={3}>
            Are you sure you want to proceed?
          </Typography>
        </PopupAsk>
      )}

      {askSaveAnyway && (
        <PopupAsk
          onClose={() => setAskSaveAnyway(false)}
          header={
            <Typography variant="bold" fontSize={18}>
              Some fields aren't filled
            </Typography>
          }
          actions={
            <>
              <Button variant="outlined" onClick={handleSubmit}>
                Save anyway
              </Button>
              <Button variant="contained" onClick={handleFillFields}>
                Back to form
              </Button>
            </>
          }
        >
          <Typography variant="bold" color="inherit">
            Are you sure you want to save package?
          </Typography>
        </PopupAsk>
      )}
    </Dialog>
  )
}
