import * as React from 'react'
import Joi, { Schema } from 'joi'
import { Color } from 'react-color'
import { t } from 'i18next'
import {
  Close as CloseIcon,
  ExpandMore as SelectIcon,
} from '@mui/icons-material'
import {
  Box,
  Dialog,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import Divider from '@mui/material/Divider'
import Chip from '@mui/material/Chip'
import { Stepper } from '../Stepper'
import { colorBackground } from '../../mui'
import { useDispatch } from '../../store'
import { postWorkflow, putWorkflow } from '../../store/package/actionCreator'
import { PreviewLookup } from '../../container/package/PreviewLookup'
import {
  phones,
  postLookups,
  socials,
  usePackagePopup,
  deepWebLookups,

  combinedPhones,
  combinedEmails,
  combinedNames,
  combinedIds,
  faceSearches,
  advancedCollectionLookups,
  sentimentAnalysys,
  findClone,
  sniperAPI,
  kyc,
  ip_geo,
  geolocation_by_number,
  web_collection,
  nameByCountry,
  phoneList,
  advCollectionPersonWallLookups,
  psychoProfile,
  api_v2_x,
  api_v2_instagram,
  api_v2_linkedin,
  sn_api_profile,
  lookup_phone_verification,
  fbMobileCollectorLookups,
  nameApi,
} from '../../hook/usePackagePopup'
import {
  FormState,
  latinOnly,
  validateForm,
  ErrorsState,
} from '../../function/validation'
import { FormError } from '../FormError'
import { InputCheckbox } from '../InputCheckbox'
import sprite_ from '../../sass/sprite.module.sass'
import { genRandomHex, getSourceName } from '../../function/common'
import { InputColor } from '../../container/package/InputColor'
import {
  Workflow,
  WorkflowType,
  Phone,
  Social,
  Source,
  DeepWeb,
  WebInt,
  CombinedPhone,
  CombinedEmail,
  CombinedNames,
  CombinedId,
  FaceSearch,
  AdvancedCollectionFacebook,
  FacebookMobileCollector,
  SentimentAnalysys,
  FindClone,
  KYC,
  WEB_Collection,
  IPGeo,
  IPGeoNumber,
  NameByCountry,
  SniperAPI,
  PhoneList,
  AdvCollectionFbPersonWall,
  PsychoProfile,
  APIv2X,
  APIv2Instagram,
  APIv2LinkedIn,
  SNApiProfile,
  LookupPhoneVerification,
  NameApi,
} from '../../store/package/reducer'
import { PopupActions } from './PopupActions'
import { TableRef } from '../datatable/types'
import { clearFieldError } from '../../store/common/actionCreator'

import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'

const schema: Schema = Joi.object({
  type: Joi.allow('phone', 'name', 'post', 'deepweb', 'webint', 'web_data_search', 'fb_person_wall_keyword'),
  title: latinOnly.label(t('field.label.title')),
  subTitle: latinOnly.label(t('field.label.subTitle')),
  price: Joi.number()
  .required()
  .min(0.01)
  .max(99)
  .label(t('field.label.price')),
  fakeCheckPrice: Joi.allow(null),
  maxNum: Joi.allow(null),
  sources: Joi.array().min(1).label(t('field.label.sources')),
  input: latinOnly.max(16).label(t('field.label.input')),
  inputIP: Joi.allow(null).label(t('field.label.inputIP')),
  inputPhone: Joi.allow(null).label(t('field.label.inputPhone')),
  button: latinOnly.max(12).label(t('field.label.button')),
  color: Joi.string().required(),

})

type Props = {
  mode: 'create' | 'edit'
  onClose: () => void
  tableRef: React.RefObject<TableRef>
  data?: Workflow
}

const webIntParams : string[] = ['quotaprofiler-lookup','quotaprofiler-crawler','quotaprofiler-cases','quotaprofiler-periodic','quotaprofiler-monitoring',
'quotawebint-lookup','quotawebint-crawler','quotawebint-cases','quotawebint-periodic','quotawebint-monitoring']

export function PopupWorkflow({ mode, tableRef, onClose, data }: Props) {
  const dispatch = useDispatch()
  const { initSourcePhone, initSourceSocials, initSourceNameApi, initSourceDeepWeb, initSourceWebInt, initCombinePhones, initCombineEmails, 
    initCombineNames, initCombineId, initFaceSearch, initAdvancedCollection, initFbMobileCollector, initAdvCollectionFbPersonWall, initSentimentAnalysys, initFindClone, initSniperApi,
    initKYC, initIPGeo, initIPGeoByNumber, initWebCollection, initNameByCountry, initPhoneList, initPsychoProfile, initAPIv2X, initAPIv2Instagram,
    initAPIv2LinkedIn, initSourceSnAPIProfiler, initSourceLookupPhoneValidator, isSourceChecked } =
    usePackagePopup()

  const [activeStep, setActiveStep] = React.useState(0)

  // const [type, setType] = React.useState<WorkflowType>(data?.type || 'phone' || 'deepweb' || 'webint' || 'combined_phone' 
  // || 'combined_email' || 'combined_name' || 'combined_id' || 'face_search' || 'additional_quotas' 
  // || 'web_data_search' || 'sentiment_analysys' || 'irbis_eye' || 'kyc' || 'web_collection' 
  // || 'ip_geo' || 'name_by_country' || 'photo_searcher'  || 'phones_list' || 'fb_person_wall_keyword' || 'ipgeo_number' 
  // || 'psycho_profile' || 'psycho_profile_summary' || 'api_v2_x' || 'api_v2_instagram')

  const [type, setType] = React.useState<WorkflowType>(data?.type || 'phone')
  
  const [title, setTitle] = React.useState(data?.title || '')
  const [subTitle, setSubTitle] = React.useState(data?.subTitle || '')
  const [price, setPrice] = React.useState(data?.price || 1)
  const [maxNum, setMaxNum] = React.useState(data?.maxNum || 0)
  const [fakeCheckPrice, setFakeCheckPrice] = React.useState(data?.fakeCheckPrice || 0)
  const [isFakeCheck, setIsfakeCheck] = React.useState(data?.fakeCheckPrice ? true : false)
  const [sourcePhone, setSourcePhone] = React.useState<Phone[]>(
    initSourcePhone(data?.sources, type),
  )
  const [sourceSocial, setSourceSocial] = React.useState<Social[]>(
    initSourceSocials(data?.sources, type),
  )

  const [sourceNameApi, setSourceNameApi] = React.useState<NameApi[]>(
    initSourceNameApi(data?.sources, type),
  )

  const [sourceDeepWeb, setSourceDeepWeb] = React.useState<DeepWeb[]>(
    initSourceDeepWeb(data?.sources, type),
  )

  const [sourceWebInt, setSourceWebInt] = React.useState<WebInt[]>(
    initSourceWebInt(data?.sources, type),
  )

  const [sourceCombinedPhones, setSourceCombinePhones] = React.useState<CombinedPhone[]>(
    initCombinePhones(data?.sources, type),
  )

  const [sourceCombinedEmails, setSourceCombineEmails] = React.useState<CombinedEmail[]>(
    initCombineEmails(data?.sources, type),
  ) 

  const [sourceCombinedNames, setSourceCombineNames] = React.useState<CombinedNames[]>(
    initCombineNames(data?.sources, type),
  ) 

  const [sourceCombinedId, setSourceCombineId] = React.useState<CombinedId[]>(
    initCombineId(data?.sources, type),
  ) 

  const [sourceFaceSearch, setSourceFaces] = React.useState<FaceSearch[]>(
    initFaceSearch(data?.sources, type),
  ) 

  const [sourceAdvancedCollection, setSourceAdvancedCollection] = React.useState<AdvancedCollectionFacebook[]> (
    initAdvancedCollection(data?.sources, type),
  )

  const [sourceFbMobileCollector, setSourceFbMobileCollector] = React.useState<FacebookMobileCollector[]> (
    initFbMobileCollector(data?.sources, type),
  )

  const [sourceAdvCollectionSourcePersonWall, setAdvCollectionSourcePersonWall] = React.useState<AdvancedCollectionFacebook[]> (
    initAdvCollectionFbPersonWall(data?.sources, type),
  )

  const [sourceSentiment, setSourceSentiment] = React.useState<SentimentAnalysys[]> (
    initSentimentAnalysys(data?.sources, type),
  )

  const [sourceFindClone, setSourceFindClone] = React.useState<FindClone[]> (
    initFindClone(data?.sources, type),
  )

  const [sourceSniperApi, setSourceSniperApi] = React.useState<SniperAPI[]> (
    initSniperApi(data?.sources, type),
  )

  const [sourceKYC, setSourceKYC] = React.useState<KYC[]> (
    initKYC(data?.sources, type),
  )

  const [sourceWebCollection, setSourceWebCollection] = React.useState<WEB_Collection[]> (
    initWebCollection(data?.sources, type),
  )

  const [sourceIPGeo, setSourceIPGeo] = React.useState<IPGeo[]> (
    initIPGeo(data?.sources, type),
  )

  const [sourceIPGeoByNumber, setSourceIPGeoByNumber] = React.useState<IPGeoNumber[]> (
    initIPGeoByNumber(data?.sources, type),
  )

  const [sourcePsychoProfile, setSourcePsychoProfile] = React.useState<PsychoProfile[]> (
    initPsychoProfile(data?.sources, type),
  )

  const [sourcePhoneList, setSourcePhoneList] = React.useState<PhoneList[]> (
    initPhoneList(data?.sources, type),
  )

  const [sourceNameByCountry, setSourceNameByCountry] = React.useState<NameByCountry[]> (
    initNameByCountry(data?.sources, type),
  )

  const [sourceAPIv2X, setSourceAPIv2X] = React.useState<APIv2X[]>(
    initAPIv2X(data?.sources, type),
  ) 

  const [sourceAPIv2Instagram, setSourceAPIv2Instagram] = React.useState<APIv2Instagram[]> (
    initAPIv2Instagram(data?.sources, type),
  )

  const [sourceAPIv2LinkedIn, setSourceAPIv2LinkedIn] = React.useState<APIv2LinkedIn[]> (
    initAPIv2LinkedIn(data?.sources, type),
  )
  
  const [sourceSnAPIProfiler, setSourceSnAPIProfiler] = React.useState<SNApiProfile[]> (
    initSourceSnAPIProfiler(data?.sources, type),
  )

  const [sourceLookupPhoneValidator, setSourceLookupPhoneValidator] = React.useState<LookupPhoneVerification[]>(
    initSourceLookupPhoneValidator(data?.sources, type),
  )
  

  const getQuotaValue = (str : string) : number => {
    if (type === 'webint' || type === 'additional_quotas') {
      try {
        const obj = JSON.parse(input)
        if (str in obj)
        return obj[str]
      } catch (e) {
        return 1
      }
    }
    return 1
  }  
  
  const [input, setInput] = React.useState( 
      data?.input && type === 'ipgeo_number' 
        && JSON.parse(data?.input).phone ? JSON.parse(data?.input).phone : data?.input || '')

  const [inputIP, setInputIP] = React.useState(
    data?.input && type === 'ipgeo_number' 
        && JSON.parse(data?.input).ip ? JSON.parse(data?.input).ip : data?.input || '')

  const [button, setButton] = React.useState(data?.button || '')
  const [color, setColor] = React.useState<Color>(
    data?.color || genRandomHex(6),
  )
  const [errors, setErrors] = React.useState<ErrorsState>(null)

  const  [quotaProfilerLookup, setQuotaProfilerLookup] = React.useState(getQuotaValue("quotaProfilerLookup").toString())
  const  [quotaProfilerCrawlers, setQuotaProfilerCrawlers] = React.useState(getQuotaValue("quotaProfilerCrawlers").toString())
  const  [quotaProfilerCases, setQuotaProfilerCases] = React.useState(getQuotaValue("quotaProfilerCases").toString())
  const  [quotaProfilerMonitoring, setQuotaProfilerMonitoring] = React.useState(getQuotaValue("quotaProfilerMonitoring").toString())
  const  [quotaProfilerPeriodic, setQuotaProfilerPeriodic] = React.useState(getQuotaValue("quotaProfilerPeriodic").toString())

  const  [quotaWebIntLookup, setQuotaWebIntLookup] = React.useState(getQuotaValue("quotaWebIntLookup").toString())
  const  [quotaWebIntCrawlers, setQuotaWebIntCrawlers] = React.useState(getQuotaValue("quotaWebIntCrawlers").toString())
  const  [quotaWebIntCases, setQuotaWebIntCases] = React.useState(getQuotaValue("quotaWebIntCases").toString())
  const  [quotaWebIntMonitoring, setQuotaWebIntMonitoring] = React.useState(getQuotaValue("quotaWebIntMonitoring").toString())
  
  //this value is for profiler and webint
  const  [quotaWebIntPeriodic, setQuotaWebIntPeriodic] = React.useState(getQuotaValue("quotaWebIntPeriodic").toString())  

  const  [quotaTrialProfilerLookup, setQuotaTrialProfilerLookup] = React.useState(getQuotaValue("quotaTrialProfilerLookup").toString())
  const  [quotaTrialProfilerCrawlers, setQuotaTrialProfilerCrawlers] = React.useState(getQuotaValue("quotaTrialProfilerCrawlers").toString())
  const  [quotaTrialProfilerCases, setQuotaTrialProfilerCases] = React.useState(getQuotaValue("quotaTrialProfilerCases").toString())
  const  [quotaTrialWebIntCrawlers, setQuotaTrialWebIntCrawlers] = React.useState(getQuotaValue("quotaTrialWebIntCrawlers").toString())
  const  [quotaTrialWebIntCases, setQuotaTrialWebIntCases] = React.useState(getQuotaValue("quotaTrialWebIntCases").toString())
  const  [quotaTrialWebIntMonitoring, setQuotaTrialWebIntMonitoring] = React.useState(getQuotaValue("quotaTrialWebIntMonitoring").toString())
  
  //this value is for profiler and webint
  const  [quotaTrialWebIntPeriodic, setQuotaTrialWebIntPeriodic] = React.useState(getQuotaValue("quotaTrialWebIntPeriodic").toString())

  //Additional Qoutas for WebInt package
  const [additionalProfilerLookup, setAdditionalProfilerLookup] = React.useState(getQuotaValue("additionalProfilerLookup").toString())
  const [additionalProfilerCrawlers, setAdditionalProfilerCrawlers] = React.useState(getQuotaValue("additionalProfilerCrawlers").toString())
  const [additionalProfilerCases, setAdditionalProfilerCases] = React.useState(getQuotaValue("additionalProfilerCases").toString())
  const [additionalProfilerMonitoring, setAdditionalProfilerMonitoring] = React.useState(getQuotaValue("additionalProfilerMonitoring").toString())
  const [additionalProfilerPeriodic, setAdditionalProfilerPeriodic] = React.useState(getQuotaValue("additionalProfilerPeriodic").toString())
  const [additionalWebIntLookup, setAdditionalWebIntLookup] = React.useState(getQuotaValue("additionalWebIntLookup").toString())
  const [additionalWebIntCrawlers, setAdditionalWebIntCrawlers] = React.useState(getQuotaValue("additionalWebIntCrawlers").toString())
  const [additionalWebIntCases, setAdditionalWebIntCases] = React.useState(getQuotaValue("additionalWebIntCases").toString())
  const [additionalWebIntMonitoring, setAdditionalWebIntMonitoring] = React.useState(getQuotaValue("additionalWebIntMonitoring").toString())
  const [additionalWebIntPeriodic, setAdditionalWebIntPeriodic] = React.useState("0")


  React.useEffect(() => {
    setErrors(null)
    dispatch(clearFieldError)
  }, [
    dispatch,
    type,
    title,
    subTitle,
    price,
    maxNum,
    sourcePhone,
    fakeCheckPrice,
    sourceSocial,
    sourceNameApi,
    sourceDeepWeb,
    sourceCombinedPhones,
    sourceCombinedEmails,
    sourceCombinedNames,
    sourceCombinedId,
    sourceFaceSearch,
    sourceSniperApi,
    sourceAdvancedCollection,
    sourceFbMobileCollector,
    sourceAdvCollectionSourcePersonWall,
    sourceSentiment,
    sourcePsychoProfile,
    sourceAPIv2X, 
    sourceAPIv2Instagram,
    sourceAPIv2LinkedIn,
    sourceSnAPIProfiler,
    sourceLookupPhoneValidator,
    input,
    quotaProfilerLookup,
    quotaProfilerCrawlers,
    quotaProfilerCases,
    quotaProfilerMonitoring,
    quotaProfilerPeriodic,
    quotaWebIntLookup,
    quotaWebIntCrawlers,
    quotaWebIntCases,
    quotaWebIntMonitoring,
    quotaWebIntPeriodic,
    quotaTrialProfilerLookup,
    quotaTrialProfilerCrawlers,
    quotaTrialProfilerCases,
    quotaTrialWebIntCrawlers,
    quotaTrialWebIntCases,
    quotaTrialWebIntMonitoring,
    quotaTrialWebIntCrawlers,
    additionalProfilerLookup,
    additionalProfilerCrawlers,
    additionalProfilerCases,
    additionalProfilerMonitoring,
    additionalProfilerPeriodic,
    additionalWebIntLookup,
    additionalWebIntCrawlers,
    additionalWebIntCases,
    additionalWebIntMonitoring,
    additionalWebIntPeriodic,
    
    button,
    color,
  ])
  const sources: Source[] = [...sourcePhone, ...sourceSocial, ...sourceNameApi, ...sourceDeepWeb, ...sourceCombinedPhones, ...sourceCombinedEmails, 
    ...sourceCombinedNames, ...sourceCombinedId, ...sourceFaceSearch, ...sourceAdvancedCollection, ...sourceFbMobileCollector, ...sourceAdvCollectionSourcePersonWall, ...sourceFindClone, ...sourceKYC, 
    ...sourceWebCollection, ...sourceIPGeo, ...nameByCountry, ...sourceSniperApi, ...sourceIPGeoByNumber, ...sourcePsychoProfile, ...sourceAPIv2X, 
    ...sourceAPIv2Instagram, ...sourceAPIv2LinkedIn, ...sourceSnAPIProfiler, ...sourceLookupPhoneValidator]

  const requestData: FormState = {
    type,
    price,
    sources,
    fakeCheckPrice,
    maxNum,
    color,
    title: title.trim(),
    subTitle: subTitle.trim(),
    input: input.trim(),
    inputIP: inputIP.trim(),
    button: button.trim(),
  }

  function handleTypeChange(type: WorkflowType) {
    setSourcePhone([])
    setSourceSocial([])
    setSourceDeepWeb([])
    setSourceWebInt([])
    setSourceAdvancedCollection([])
    setAdvCollectionSourcePersonWall([])
    setSourceFaces([])
    setSourceSentiment([])
    setSourceFindClone([])
    setSourceSniperApi([])
    setSourceKYC([])
    setSourceWebCollection([])
    setSourceIPGeo([])
    setSourceIPGeoByNumber([])
    setSourceNameByCountry([])
    setSourceAPIv2X([])
    setSourceAPIv2Instagram([])
    setType(type)
  }

  function handleKYCChange(source: KYC) {
    setSourceKYC((state) => {
      const index = state.indexOf(source)
      const newState = [...state]
      if (index > -1) {
        newState.splice(index, 1)
        return newState
      } else {
        return [...state, source]
      }
    })
  }

  function handleWebCollectionChange(source: WEB_Collection) {
    setSourceWebCollection((state) => {
      const index = state.indexOf(source)
      const newState = [...state]
      if (index > -1) {
        newState.splice(index, 1)
        return newState
      } else {
        return [...state, source]
      }
    })
  }

  function handleAPIv2XChange(source: APIv2X) {
    setSourceAPIv2X((state) => {
      const index = state.indexOf(source)
      const newState = [...state]
      if (index > -1) {
        newState.splice(index, 1)
        return newState
      } else {
        return [...state, source]
      }
    })
  }

  function handleAPIv2InstagramChange(source: APIv2Instagram) {
    setSourceAPIv2Instagram((state) => {
      const index = state.indexOf(source)
      const newState = [...state]
      if (index > -1) {
        newState.splice(index, 1)
        return newState
      } else {
        return [...state, source]
      }
    })
  }

  function handleAPIv2LinkedInChange(source: APIv2LinkedIn) {
    setSourceAPIv2LinkedIn((state) => {
      const index = state.indexOf(source)
      const newState = [...state]
      if (index > -1) {
        newState.splice(index, 1)
        return newState
      } else {
        return [...state, source]
      }
    })
  }

  function handleSnAPIProfilerChange(source: SNApiProfile) {
    setSourceSnAPIProfiler((state) => {
      const index = state.indexOf(source)
      const newState = [...state]
      if (index > -1) {
        newState.splice(index, 1)
        return newState
      } else {
        return [...state, source]
      }
    })
  }

  function handleIPGeoChange(source: IPGeo) {
    setSourceIPGeo((state) => {
      const index = state.indexOf(source)
      const newState = [...state]
      if (index > -1) {
        newState.splice(index, 1)
        return newState
      } else {
        return [...state, source]
      }
    })
  }

  function handleIPGeoByNumberChange(source: IPGeoNumber) {
    setSourceIPGeoByNumber((state) => {
      const index = state.indexOf(source)
      const newState = [...state]
      if (index > -1) {
        newState.splice(index, 1)
        return newState
      } else {
        return [...state, source]
      }
    })
  }

  function handleNameByCountry(source: NameByCountry) {
    setSourceNameByCountry((state) => {
      const index = state.indexOf(source)
      const newState = [...state]
      if (index > -1) {
        newState.splice(index, 1)
        return newState
      } else {
        return [...state, source]
      }
    })
  }

  function handleFindCloneChange(source: FindClone) {
    setSourceFindClone((state) => {
      const index = state.indexOf(source)
      const newState = [...state]
      if (index > -1) {
        newState.splice(index, 1)
        return newState
      } else {
        return [...state, source]
      }
    })
  }

  function handleSniperApiChange(source: SniperAPI) {
    setSourceSniperApi((state) => {
      const index = state.indexOf(source)
      const newState = [...state]
      if (index > -1) {
        newState.splice(index, 1)
        return newState
      } else {
        return [...state, source]
      }
    })
  }

  function handleSentimentAnalysysChange(source: SentimentAnalysys) {
    setSourceSentiment((state) => {
      const index = state.indexOf(source)
      const newState = [...state]
      if (index > -1) {
        newState.splice(index, 1)
        return newState
      } else {
        return [...state, source]
      }
    })
  }

  function handleListPhoneSourceChange(source: PhoneList) {
    setSourcePhoneList((state) => {
      const index = state.indexOf(source)
      const newState = [...state]
      if (index > -1) {
        newState.splice(index, 1)
        return newState
      } else {
        return [...state, source]
      }
    })
  }

  function handlePhoneSourceChange(source: Phone) {
    setSourcePhone((state) => {
      const index = state.indexOf(source)
      const newState = [...state]
      if (index > -1) {
        newState.splice(index, 1)
        return newState
      } else {
        return [...state, source]
      }
    })
  }

  function handleCombinePhoneSourceChange(source: CombinedPhone) {
    setSourceCombinePhones((state) => {
      const index = state.indexOf(source)
      const newState = [...state]

      if (index > -1) {
        newState.splice(index, 1)
        return newState
      } else {
        return [...state, source]
      }
    })
  }
 
  function handleCombineNamesSourceChange(source: CombinedNames) {
    setSourceCombineNames((state) => {
      const index = state.indexOf(source)
      const newState = [...state]

      if (index > -1) {
        newState.splice(index, 1)
        return newState
      } else {
        return [...state, source]
      }
    })
  }

  function handleFaceSearch (source : FaceSearch) {
    setSourceFaces((state) => {
      const index = state.indexOf(source)
      const newState = [...state]

      if (index > -1) {
        newState.splice(index, 1)
        return newState
      } else {
        return [...state, source]
      }
    })
  }

  function handleCombineidSourceChange(source: CombinedId) {
    setSourceCombineId((state) => {
      const index = state.indexOf(source)
      const newState = [...state]

      if (index > -1) {
        newState.splice(index, 1)
        return newState
      } else {
        return [...state, source]
      }
    })
  }

  function handleCombineEmailsSourceChange(source: CombinedEmail) {
    setSourceCombineEmails((state) => {
      const index = state.indexOf(source)
      const newState = [...state]

      if (index > -1) {
        newState.splice(index, 1)
        return newState
      } else {
        return [...state, source]
      }
    })
  }

  function handleSocialSourceChange(source: Social) {
    setSourceSocial((state) => {
      const index = state.indexOf(source)
      const newState = [...state]

      if (index > -1) {
        newState.splice(index, 1)
        return newState
      } else {
        return [...state, source]
      }
    })
  }

  function handleNameApiSourceChange(source: NameApi) {
    setSourceNameApi((state) => {
      const index = state.indexOf(source)
      const newState = [...state]
      if (index > -1) {
        newState.splice(index, 1)
        return newState
      } else {
        return [...state, source]
      }
    })
  }

  function handleDeepWebChange(source: DeepWeb) {
    setSourceDeepWeb((state) => {
      const index = state.indexOf(source)
      const newState = [...state]
      if (index > -1) {
        newState.splice(index, 1)
        return newState
      } else {
        return [...state, source]
      }
    })
  }

  function handleAddvancedSourceChange(source: AdvancedCollectionFacebook) {
    setSourceAdvancedCollection((state) => {
      const index = state.indexOf(source)
      const newState = [...state]

      if (index > -1) {
        newState.splice(index, 1)
        return newState
      } else {
        return [...state, source]
      }
    })
  }

  function handleFbMobileCollectorSorceChange(source: FacebookMobileCollector) {
    setSourceFbMobileCollector((state) => {
      const index = state.indexOf(source)
      const newState = [...state]
      if (index > -1) {
        newState.splice(index, 1)
        return newState
      } else {
        return [...state, source]
      }
    })
  }

  function handlePsychoProfileChange(source: PsychoProfile) {
    setSourcePsychoProfile((state) => {
      const index = state.indexOf(source)
      const newState = [...state]
      if (index > -1) {
        newState.splice(index, 1)
        return newState
      } else {
        return [...state, source]
      }
    })
  }

  function handleLookupPhoneValidator(source : LookupPhoneVerification) {
    setSourceLookupPhoneValidator((state) => {
      const index = state.indexOf(source)
      const newState = [...state]
      if (index > -1) {
        newState.splice(index, 1)
        return newState
      } else {
        return [...state, source]
      }
    })
  }

  function handleAdvCollectionSourcePersonWall(source: AdvCollectionFbPersonWall) {
    setAdvCollectionSourcePersonWall((state) => {
      const index = state.indexOf(source)
      const newState = [...state]
      if (index > -1) {
        newState.splice(index, 1)
        return newState
      } else {
        return [...state, source]
      }
    })
  }

  function handleFakeCheckPrice(
    e: React.SyntheticEvent<Element, Event>,
    checked: boolean,
  ) {
    if (checked) {
      setIsfakeCheck(true)
    } else {
      setIsfakeCheck(false)
      setFakeCheckPrice(0)
    }
  }

  function returnSourcesByType(type : WorkflowType) {
    console.log('Test: ',type)
    switch (type) {
      case 'phone': {
        return sourcePhone
      }
      case 'name': {
        return sourceSocial
      }
      case 'name_api': {
        return sourceNameApi
      }
      case 'post': {
        return sourceSocial
      } 
      case 'deepweb': {
        return sourceDeepWeb
      }
      case 'face_search': {
        return sourceFaceSearch
      }
      case 'combined_phone': {
        return sourceCombinedPhones
      }
      case 'combined_name': {
        return sourceCombinedNames
      }
      case 'combined_id': {
        return sourceCombinedId
      }
      case 'combined_email': {
        return sourceCombinedEmails
      }
      case 'web_data_search': {
        return sourceAdvancedCollection
      }
      case 'sentiment_analysys': {
        return sourceSentiment
      }
      case 'irbis_eye': {
        return sourceFindClone
      }
      case 'photo_searcher': {
        return sourceSniperApi
      }
      case 'kyc': {
        return sourceKYC
      }
      case 'web_collection': {
        return sourceWebCollection
      }
      case 'ip_geo': {
        return sourceIPGeo
      }
      case 'ipgeo_number' : {
        return sourceIPGeoByNumber
      }
      case 'name_by_country' : {
        return sourceNameByCountry
      }
      case 'phone_list' : {
        return sourcePhoneList
      }
      case 'fb_person_wall_keyword': {
        return sourceAdvCollectionSourcePersonWall
      }
      case 'psycho_profile' : {
        return sourcePsychoProfile
      }
      case 'psycho_profile_summary' : {
        return sourcePsychoProfile
      }
      case 'api_v2_x' : {
        return sourceAPIv2X
      }
      case 'api_v2_instagram' : {
        return sourceAPIv2Instagram
      }
      case 'api_v2_linkedin' : {
        return sourceAPIv2LinkedIn
      }
      case 'sn_api_profiler' : {
        return sourceSnAPIProfiler
      }
      case 'lookup_phone_verification' : {
        return sourceLookupPhoneValidator
      }
      case 'lookup_phone_name_verification' : {
        return sourceCombinedPhones
      }
      case 'real_phone' : {
        return sourceCombinedPhones
      }
      case 'sn_api_profiler_fb_friends' : {
        return ['osintFbFriends']
      }
      case 'sn_api_profiler_fb_posts' : {
        return ['osintFbPosts']
      }
      case 'sn_api_profiler_insta_friends' : {
        return ['osintInstaFriends']
      } 
      case 'sn_api_profiler_insta_posts' : {
        return ['osintInstaPosts']
      }
      case 'irbis_facebook' : {
        return sourceFbMobileCollector
      }
    }
  }

  function handleNext() {
    requestData.sources = returnSourcesByType(type)    
    if (type === 'additional_quotas'){
      setPrice(0)
    }
    if (type === 'webint' || type === 'additional_quotas') {
      setErrors(null)
      setActiveStep(1)
      return
    }

    if (isFakeCheck == false ) {
      requestData.fakeCheckPrice = null
    }

    const error = validateForm(schema, requestData)
    if (error) {
      setErrors(error)
    }
    else {
      setErrors(null)
      setActiveStep(1)
    }
  }

  function handleSubmit() {
    requestData.sources = returnSourcesByType(type) 
    if (type === 'webint') {
      requestData.sources = webIntParams
      requestData.input = JSON.stringify(webIntInputObject)
    }
    if (type === 'additional_quotas') {
      requestData.sources = webIntParams
      requestData.input = JSON.stringify(additionalWebintQuota)
    }
    if (mode === 'create') {
      console.log("requestData = ",requestData)
      dispatch(postWorkflow(requestData, () => tableRef.current?.refresh()))
    }
    else {
      dispatch(
        putWorkflow(requestData, data?.id as number, () =>
          tableRef.current?.refresh(),
        ),
      )
    }
    onClose()
  }

  const additionalWebintQuota = {
    additionalProfilerLookup : additionalProfilerLookup,
    additionalProfilerCrawlers : additionalProfilerCrawlers,
    additionalProfilerCases : additionalProfilerCases,
    additionalProfilerMonitoring : additionalProfilerMonitoring,
    additionalProfilerPeriodic : additionalProfilerPeriodic,
    additionalWebIntLookup : additionalWebIntLookup,
    additionalWebIntCrawlers : additionalWebIntCrawlers,
    additionalWebIntCases : additionalWebIntCases,
    additionalWebIntMonitoring : additionalWebIntMonitoring,
    additionalWebIntPeriodic : additionalWebIntPeriodic, 
  }

  const webIntInputObject = {
    quotaProfilerLookup : quotaProfilerLookup,
    quotaProfilerCrawlers : quotaProfilerCrawlers,
    quotaProfilerCases : quotaProfilerCases,
    quotaProfilerMonitoring : quotaProfilerMonitoring,
    quotaProfilerPeriodic : quotaProfilerPeriodic,
    quotaWebIntLookup : quotaWebIntLookup,
    quotaWebIntCrawlers : quotaWebIntCrawlers,
    quotaWebIntCases : quotaWebIntCases,
    quotaWebIntMonitoring : quotaWebIntMonitoring,
    quotaWebIntPeriodic : quotaWebIntPeriodic, 
    
    quotaTrialProfilerLookup : quotaTrialProfilerLookup,
    quotaTrialProfilerCrawlers : quotaTrialProfilerCrawlers,
    quotaTrialProfilerCases : quotaTrialProfilerCases,
    quotaTrialWebIntCrawlers : quotaTrialWebIntCrawlers,
    quotaTrialWebIntCases : quotaTrialWebIntCases,
    quotaTrialWebIntMonitoring : quotaTrialWebIntMonitoring,
    quotaTrialWebIntPeriodic : quotaTrialWebIntPeriodic,
  } 

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  
  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    )
  }
  
  function a11yProps(index: number) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    }
  }

  const [value, setValue] = React.useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  //console.log(webIntInputObject)

  return (
    <Dialog open={true} fullWidth onClose={onClose}>
      <DialogTitle sx={{ fontSize: 20 }}>
        {mode === 'create' && t('package.create.lookup')}
        {mode === 'edit' && `${t('package.edit.lookup')}: ${data?.title}`}
        <IconButton className="button__close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <Box my={2}>
        <Stepper
          steps={[t('package.steps.workflow'), t('package.steps.preview')]}
          activeStep={activeStep}
        />
      </Box>

      {activeStep === 0 ? (
        <Stack
          mb={3}
          spacing={2}
          sx={{ padding: 1, height: 530, overflowY: 'auto' }}
        >
          {/** Type */}
          <FormControl>
            <FormLabel>{t('field.label.type')}</FormLabel>
            <Select
              value={type}
              IconComponent={SelectIcon}
              onChange={(e) => handleTypeChange(e.target.value as WorkflowType)}
            >
              <MenuItem value="phone">Phone</MenuItem>
              <MenuItem value="name">Name (Crawlers)</MenuItem>
              <MenuItem value="name_api">Name Api (IRBIS Social)</MenuItem>
              <MenuItem value="post">Post</MenuItem>
              <MenuItem value="deepweb">Deep WEB</MenuItem>
              <MenuItem value="webint">WebInt</MenuItem>
              <MenuItem value="combined_phone">Advanced Phone Lookup</MenuItem>
              <MenuItem value="phone_list">Advanced Phone Lookup (List)</MenuItem>
              <MenuItem value="combined_email">Advanced E-mail Lookup</MenuItem>
              <MenuItem value="combined_name">Advanced Name Lookup</MenuItem>
              <MenuItem value="name_by_country">Advanced Name Lookup By Country</MenuItem>
              <MenuItem value="combined_id">Advanced ID Lookup</MenuItem>
              <MenuItem value="face_search">Face Search</MenuItem>
              <MenuItem value="additional_quotas">Add Quotas</MenuItem>
              <MenuItem value="web_data_search">Facebook API (Crawlers)</MenuItem>
              <MenuItem value="irbis_facebook">Facebook API (IRBIS Social)</MenuItem>
              <MenuItem value="fb_person_wall_keyword">Facebook API (person wall keyword, Crawlers)</MenuItem>
              <MenuItem value="sentiment_analysys">Sentiment Analysys</MenuItem>
              <MenuItem value="irbis_eye">Irbis Eye</MenuItem>
              <MenuItem value="photo_searcher">Sniper API</MenuItem>
              <MenuItem value="kyc">KYC</MenuItem>
              <MenuItem value="web_collection">WEB Collection</MenuItem>
              <MenuItem value="ip_geo">Geolocation</MenuItem>
              <MenuItem value="ipgeo_number">Geolocation By Phone</MenuItem>
              <MenuItem value="psycho_profile">PsychoProfile and Summary</MenuItem>
              <MenuItem value="api_v2_x">API v.2 - X (Twitter)</MenuItem>
              <MenuItem value="api_v2_instagram">API v.2 - Instagram</MenuItem>
              <MenuItem value="api_v2_linkedin">API v.2 - LinkedIn</MenuItem>
              <MenuItem value="lookup_phone_verification">Validate Phone Lookup Results</MenuItem>
              <MenuItem value="lookup_phone_name_verification">Validate Phone Lookup By Name</MenuItem>
              <MenuItem value="real_phone">Real Phone</MenuItem>
              <MenuItem value="psycho_profile_summary">OSINT: PsychoProfile</MenuItem>
              <MenuItem value="sn_api_profiler">OSINT: Social Network API Profiler (Common Profile Builder)</MenuItem>
              <MenuItem value="sn_api_profiler_fb_friends">OSINT: Social Network API Profiler (Facebook Friends)</MenuItem>
              <MenuItem value="sn_api_profiler_fb_posts">OSINT: Social Network API Profiler (Facebook Posts)</MenuItem>
              <MenuItem value="sn_api_profiler_insta_friends">OSINT: Social Network API Profiler (Instagram Friends)</MenuItem>
              <MenuItem value="sn_api_profiler_insta_posts">OSINT: Social Network API Profiler (Instagram Posts)</MenuItem>
            </Select>
          </FormControl>

          {/** Title */}
          <FormControl fullWidth>
            <FormLabel required>{t('field.label.title')}</FormLabel>
            <OutlinedInput
              value={title}
              placeholder={t('field.hint.title')}
              onChange={(e) => setTitle(e.target.value)}
            />
            <FormError error={errors?.title} />
          </FormControl>

          {/** Subtitle */}
          <FormControl fullWidth>
            <FormLabel required>{t('field.label.subTitle')}</FormLabel>
            <OutlinedInput
              value={subTitle}
              placeholder={t('field.hint.subtitle')}
              onChange={(e) => setSubTitle(e.target.value)}
            />
            <FormError error={errors?.subTitle} />
          </FormControl>

          {/** Price */}
          { type !== 'webint' && type !== 'additional_quotas' &&
          <FormControl fullWidth>
            <FormLabel required>{t('field.label.price')}</FormLabel>
            <OutlinedInput
              type="number"
              value={price}
              startAdornment={
                <InputAdornment position="start">€</InputAdornment>
              }
              onChange={(e) => setPrice(Number(e.target.value))}
              inputProps={{
                min: 0,
                max: 99,
              }}
            />
            <FormError error={errors?.price} />
          </FormControl>
          }
          {type === 'combined_phone' &&
            <FormControl fullWidth>
              <Stack direction="row" alignItems="center">
                <FormControlLabel
                  control={<InputCheckbox defaultChecked={isFakeCheck} />}
                  label="Fake Check price"
                  onChange={handleFakeCheckPrice}

                />
              </Stack>
              <OutlinedInput
                type="number"
                value={fakeCheckPrice}
                disabled={!isFakeCheck}
                startAdornment={
                  <InputAdornment position="start">€</InputAdornment>
                }
                onChange={(e) => setFakeCheckPrice(Number(e.target.value))}
                inputProps={{
                  min: 0,
                  max: 99,
                }}
              />
              <FormError error={errors?.price} />
            </FormControl>
          }
          {type === 'phone_list' &&
            <FormControl fullWidth>
              <FormLabel required>{"Max numbers in list:"}</FormLabel>
              <OutlinedInput
                type="number"
                value={maxNum}
                onChange={(e) => setMaxNum(Number(e.target.value))}
              />
              <FormError error={errors?.price} />
            </FormControl>
          }

          {/** Sources */}
          <FormControl fullWidth>
            { (type !== 'webint' && type !== 'sn_api_profiler_fb_friends' && 
            type !== 'sn_api_profiler_fb_posts' && type !== 'sn_api_profiler_insta_friends' &&
            type !== 'sn_api_profiler_insta_posts' ) &&
            <FormLabel required>{t('field.label.sources')}</FormLabel>
            }
            <Grid container>
                {type === 'phone' &&
                phones.map((el) => (
                  <Grid key={el} item xs={4}>
                    <Stack direction="row" alignItems="center">
                      <InputCheckbox
                        checked={isSourceChecked(el, sourcePhone)}
                        onChange={() => handlePhoneSourceChange(el)}
                      />
                      <Box className={sprite_['social__small_' + el]} />
                      <Typography textTransform="capitalize" ml={1}>
                        {getSourceName(el)}
                      </Typography>
                    </Stack>
                  </Grid>
                ))}
                {type === 'phone_list' &&
                phoneList.map((el) => (
                  <Grid key={el} item xs={4}>
                    <Stack direction="row" alignItems="center">
                      <InputCheckbox
                        checked={isSourceChecked(el, sourcePhoneList)}
                        onChange={() => handleListPhoneSourceChange(el)}
                      />
                      <Box className={sprite_['social__small_' + el]} />
                      <Typography textTransform="capitalize" ml={1}>
                        {getSourceName(el)}
                      </Typography>
                    </Stack>
                  </Grid>
                ))}
                {type === 'name' &&
                socials.map((el) => (
                  <Grid key={el} item xs={4}>
                    <Stack direction="row" alignItems="center">
                      <InputCheckbox
                        checked={isSourceChecked(el, sourceSocial)}
                        onChange={() => handleSocialSourceChange(el)}
                      />
                      <Box className={sprite_['social__small_' + el]} />
                      <Typography textTransform="capitalize" ml={1}>
                        {getSourceName(el)}
                      </Typography>
                    </Stack>
                  </Grid>
                ))} 
                {type === 'name_api' &&
                nameApi.map((el) => (
                  <Grid key={el} item xs={4}>
                    <Stack direction="row" alignItems="center">
                      <InputCheckbox
                        checked={isSourceChecked(el, sourceNameApi)}
                        onChange={() => handleNameApiSourceChange(el)}
                      />
                      <Box className={sprite_['social__small_' + el]} />
                      <Typography textTransform="capitalize" ml={1}>
                        {getSourceName(el)}
                      </Typography>
                    </Stack>
                  </Grid>
                ))}


                {type === 'post' &&
                postLookups.map((el) => (
                  <Grid key={el} item xs={4}>
                    <Stack direction="row" alignItems="center">
                      <InputCheckbox
                        checked={isSourceChecked(el, sourceSocial)}
                        onChange={() => handleSocialSourceChange(el)}
                      />
                      <Box className={sprite_['social__small_' + el]} />
                      <Typography textTransform="capitalize" ml={1}>
                        {getSourceName(el)}
                      </Typography>
                    </Stack>
                  </Grid>
                ))}
                {type === 'deepweb' &&
                deepWebLookups.map((el) => (
                  <Grid key={el} item xs={4}>
                    <Stack direction="row" alignItems="center">
                      <InputCheckbox
                        checked={isSourceChecked(el, sourceDeepWeb)}
                        onChange={() => handleDeepWebChange(el)}
                      />
                      <Box className={sprite_['social__small_'+el]} />
                      <Typography textTransform="capitalize" ml={1}>
                        {getSourceName(el)}
                      </Typography>
                    </Stack>
                  </Grid>
                ))}
                {type === 'sentiment_analysys' &&
                sentimentAnalysys.map((el) => (
                  <Grid key={el} item xs={4}>
                    <Stack direction="row" alignItems="center">
                      <InputCheckbox
                        checked={isSourceChecked(el, sourceSentiment)}
                        onChange={() => handleSentimentAnalysysChange(el)}
                      />
                      <Box className={sprite_['social__small_'+el]} />
                      <Typography textTransform="capitalize" ml={1}>
                        {getSourceName(el)}
                      </Typography>
                    </Stack>
                  </Grid>
                ))}
                {type === 'kyc' &&
                kyc.map((el) => (
                  <Grid key={el} item xs={4}>
                    <Stack direction="row" alignItems="center">
                      <InputCheckbox
                        checked={isSourceChecked(el, sourceKYC)}
                        onChange={() => handleKYCChange(el)}
                      />
                      <Box className={sprite_['social__small_' + el]} />
                      <Typography textTransform="capitalize" ml={1}>
                        {getSourceName(el)}
                      </Typography>
                    </Stack>
                  </Grid>
                ))}
                {type === 'web_collection' &&
                web_collection.map((el) => (
                  <Grid key={el} item xs={4}>
                    <Stack direction="row" alignItems="center">
                      <InputCheckbox
                        checked={isSourceChecked(el, sourceWebCollection)}
                        onChange={() => handleWebCollectionChange(el)}
                      />
                      <Box className={sprite_['social__small_' + el]} />
                      <Typography textTransform="capitalize" ml={1}>
                        {getSourceName(el)}
                      </Typography>
                    </Stack>
                  </Grid>
                ))}
                {type === 'ip_geo' &&
                ip_geo.map((el) => (
                  <Grid key={el} item xs={4}>
                    <Stack direction="row" alignItems="center">
                      <InputCheckbox
                        checked={isSourceChecked(el, sourceIPGeo)}
                        onChange={() => handleIPGeoChange(el)}
                      />
                      <Box className={sprite_['social__small_' + el]} />
                      <Typography textTransform="capitalize" ml={1}>
                        {getSourceName(el)}
                      </Typography>
                    </Stack>
                  </Grid>
                ))}
                {type === 'ipgeo_number' &&
                geolocation_by_number.map((el) => (
                  <Grid key={el} item xs={4}>
                    <Stack direction="row" alignItems="center">
                      <InputCheckbox
                        checked={isSourceChecked(el, sourceIPGeoByNumber)}
                        onChange={() => handleIPGeoByNumberChange(el)}
                      />
                      <Box className={sprite_['social__small_' + el]} />
                      <Typography textTransform="capitalize" ml={1}>
                        {getSourceName(el)}
                      </Typography>
                    </Stack>
                  </Grid>
                ))}
                {type === 'name_by_country' &&
                nameByCountry.map((el) => (
                  <Grid key={el} item xs={4}>
                    <Stack direction="row" alignItems="center">
                      <InputCheckbox
                        checked={isSourceChecked(el, sourceNameByCountry)}
                        onChange={() => handleNameByCountry(el)}
                      />
                      <Box className={sprite_['social__small_' + el]} />
                      <Typography textTransform="capitalize" ml={1}>
                        {getSourceName(el)}
                      </Typography>
                    </Stack>
                  </Grid>
                ))}
                {type === 'irbis_eye' &&
                findClone.map((el) => (
                  <Grid key={el} item xs={4}>
                    <Stack direction="row" alignItems="center">
                      <InputCheckbox
                        checked={isSourceChecked(el, sourceFindClone)}
                        onChange={() => handleFindCloneChange(el)}
                      />
                      <Box className={sprite_['social__small_'+el]} />
                      <Typography textTransform="capitalize" ml={1}>
                        {getSourceName(el)}
                      </Typography>
                    </Stack>
                  </Grid>
                ))}
                {type === 'photo_searcher' &&
                sniperAPI.map((el) => (
                  <Grid key={el} item xs={4}>
                    <Stack direction="row" alignItems="center">
                      <InputCheckbox
                        checked={isSourceChecked(el, sourceSniperApi)}
                        onChange={() => handleSniperApiChange(el)}
                      />
                      <Box className={sprite_['social__small_' + el]} />
                      <Typography textTransform="capitalize" ml={1}>
                        {getSourceName(el)}
                      </Typography>
                    </Stack>
                  </Grid>
                ))}
                {type === 'web_data_search' && 
                advancedCollectionLookups.map( el => (
                      <Grid key={el} item xs={6}>
                    <Stack direction="row" alignItems="center">
                      <InputCheckbox
                        checked={isSourceChecked(el, sourceAdvancedCollection)}
                        onChange={() => handleAddvancedSourceChange(el)}
                      />
                      <Box className={sprite_['social__small_' + el]} />
                      <Typography textTransform="capitalize" ml={1}>
                        {getSourceName(el)}
                      </Typography>
                    </Stack>
                  </Grid>
                ))}
                {type === 'irbis_facebook' && 
                fbMobileCollectorLookups.map( el => (
                      <Grid key={el} item xs={6}>
                    <Stack direction="row" alignItems="center">
                      <InputCheckbox
                        checked={isSourceChecked(el, sourceFbMobileCollector)}
                        onChange={() => handleFbMobileCollectorSorceChange(el)}
                      />
                      <Box className={sprite_['social__small_' + el]} />
                      <Typography textTransform="capitalize" ml={1}>
                        {getSourceName(el)}
                      </Typography>
                    </Stack>
                  </Grid>
                ))}
                {type === 'api_v2_x' && 
                api_v2_x.map( el => (
                      <Grid key={el} item xs={6}>
                    <Stack direction="row" alignItems="center">
                      <InputCheckbox
                        checked={isSourceChecked(el, sourceAPIv2X)}
                        onChange={() => handleAPIv2XChange(el)}
                      />
                      <Box className={sprite_['social__small_' + el]} />
                      <Typography textTransform="capitalize" ml={1}>
                        {getSourceName(el)}
                      </Typography>
                    </Stack>
                  </Grid>
                ))}
                {type === 'api_v2_instagram' && 
                api_v2_instagram.map( el => (
                      <Grid key={el} item xs={6}>
                    <Stack direction="row" alignItems="center">
                      <InputCheckbox
                        checked={isSourceChecked(el, sourceAPIv2Instagram)}
                        onChange={() => handleAPIv2InstagramChange(el)}
                      />
                      <Box className={sprite_['social__small_' + el]} />
                      <Typography textTransform="capitalize" ml={1}>
                        {getSourceName(el)}
                      </Typography>
                    </Stack>
                  </Grid>
                ))}
                {type === 'api_v2_linkedin' && 
                api_v2_linkedin.map( el => (
                      <Grid key={el} item xs={6}>
                    <Stack direction="row" alignItems="center">
                      <InputCheckbox
                        checked={isSourceChecked(el, sourceAPIv2LinkedIn)}
                        onChange={() => handleAPIv2LinkedInChange(el)}
                      />
                      <Box className={sprite_['social__small_' + el]} />
                      <Typography textTransform="capitalize" ml={1}>
                        {getSourceName(el)}
                      </Typography>
                    </Stack>
                  </Grid>
                ))}  
                {type === 'sn_api_profiler' && 
                sn_api_profile.map( el => (
                      <Grid key={el} item xs={6}>
                    <Stack direction="row" alignItems="center">
                      <InputCheckbox
                        checked={isSourceChecked(el, sourceSnAPIProfiler)}
                        onChange={() => handleSnAPIProfilerChange(el)}
                      />
                      <Box className={sprite_['social__small_' + el]} />
                      <Typography textTransform="capitalize" ml={1}>
                        {getSourceName(el)}
                      </Typography>
                    </Stack>
                  </Grid>
                ))} 
                {type === 'fb_person_wall_keyword' &&
                advCollectionPersonWallLookups.map(el => (
                  <Grid key={el} item xs={6}>
                    <Stack direction="row" alignItems="center">
                      <InputCheckbox
                        checked={isSourceChecked(el, sourceAdvCollectionSourcePersonWall)}
                        onChange={() => handleAdvCollectionSourcePersonWall(el)}
                      />
                      <Box className={sprite_['social__small_' + el]} />
                      <Typography textTransform="capitalize" ml={1}>
                        {getSourceName(el)}
                      </Typography>
                    </Stack>
                  </Grid>
                ))}
                {type === 'psycho_profile' &&
                psychoProfile.map(el => (
                  <Grid key={el} item xs={6}>
                    <Stack direction="row" alignItems="center">
                      <InputCheckbox
                        checked={isSourceChecked(el, sourcePsychoProfile)}
                        onChange={() => handlePsychoProfileChange(el)}
                      />
                      <Box className={sprite_['social__small_' + el]} />
                      <Typography textTransform="capitalize" ml={1}>
                        {getSourceName(el)}
                      </Typography>
                    </Stack>
                  </Grid>
                ))}
                {type === 'psycho_profile_summary' &&
                psychoProfile.map(el => (
                  <Grid key={el} item xs={6}>
                    <Stack direction="row" alignItems="center">
                      <InputCheckbox
                        checked={isSourceChecked(el, sourcePsychoProfile)}
                        onChange={() => handlePsychoProfileChange(el)}
                      />
                      <Box className={sprite_['social__small_' + el]} />
                      <Typography textTransform="capitalize" ml={1}>
                        {getSourceName(el)}
                      </Typography>
                    </Stack>
                  </Grid>
                ))}
                {type === 'lookup_phone_verification' &&
                lookup_phone_verification.map(el => (
                  <Grid key={el} item xs={6}>
                    <Stack direction="row" alignItems="center">
                      <InputCheckbox
                        checked={isSourceChecked(el, sourceLookupPhoneValidator)}
                        onChange={() => handleLookupPhoneValidator(el)}
                      />
                      <Box className={sprite_['social__small_' + el]} />
                      <Typography textTransform="capitalize" ml={1}>
                        {getSourceName(el)}
                      </Typography>
                    </Stack>
                  </Grid>
                ))}
                {type === 'webint' &&
                <Box
                sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}
                >
                <Tabs
                  orientation="vertical"
                  variant="scrollable"
                  value={value}
                  onChange={handleChange}
                  aria-label="Vertical tabs example"
                  sx={{ borderRight: 1, borderColor: 'divider' }}
                >
                  <Tab label="Quota" {...a11yProps(0)} />
                  <Tab label="Trial" {...a11yProps(1)} />

                </Tabs>
                <TabPanel value={value} index={0}>
                <FormControl fullWidth required>
                <Grid container >
                <Grid item xs = {6} >
                <Box  component="form" sx={{"& .MuiTextField-root": { m: 1, width: "100%" }, display : 'flex', flexDirection: 'column', p: 1, alignItems: 'center'}} noValidate   >
                <Typography ml={1}>Profiler</Typography>
                <TextField value={quotaProfilerLookup} onChange={ (e) => setQuotaProfilerLookup(e.target.value)} label="Phone numbers" type="number"  defaultValue={1} InputLabelProps={{shrink: true}} inputProps={{min: 1, style: { textAlign: 'center' }}}/>
                <TextField value={quotaProfilerCrawlers} onChange={ (e) => setQuotaProfilerCrawlers(e.target.value)} required id="outlined-number" label="Social networks" type="number" defaultValue={1} InputLabelProps={{shrink: true}} inputProps={{min: 1, style: { textAlign: 'center' }}}/>
                <TextField value={quotaProfilerCases} onChange={ (e) => setQuotaProfilerCases(e.target.value)} required id="outlined-number" label="Cases" type="number" defaultValue={1} InputLabelProps={{shrink: true}} inputProps={{min: 1, style: { textAlign: 'center' }}}/>
                </Box>
                </Grid>
                <Grid item xs = {6} >
                <Box  component="form" sx={{"& .MuiTextField-root": { m: 1, width: "100%" }, display : 'flex', flexDirection: 'column', p: 1, alignItems: 'center'}} noValidate autoComplete="off">
                <Typography>WebInt</Typography>
                <TextField value={quotaWebIntCrawlers} onChange={ (e) => setQuotaWebIntCrawlers(e.target.value)} required id="outlined-number" label="Tasks" type="number" defaultValue={1} InputLabelProps={{shrink: true}} inputProps={{min: 1, style: { textAlign: 'center' }}}/>
                <TextField value={quotaWebIntCases} onChange={ (e) => setQuotaWebIntCases(e.target.value)} required id="outlined-number" label="Cases" type="number" defaultValue={1} InputLabelProps={{shrink: true}} inputProps={{min: 1, style: { textAlign: 'center' }}}/>
                <TextField value={quotaWebIntMonitoring} onChange={ (e) => setQuotaWebIntMonitoring(e.target.value)} required id="outlined-number" label="Monitored groups" type="number" defaultValue={1} InputLabelProps={{shrink: true}} inputProps={{min: 1, style: { textAlign: 'center' }}}/>
                </Box>
                </Grid>
                </Grid>
                <Grid item xs = {8} >
                <Box  component="form" sx={{"& .MuiTextField-root": { m: 1, width: "50%" }, display : 'flex', flexDirection: 'row-reverse', p: 0, alignItems: 'center'}} noValidate autoComplete="off">
                <TextField value={quotaWebIntPeriodic} onChange={ (e) => setQuotaWebIntPeriodic(e.target.value)} required id="outlined-number" label="Expired in (days):" type="number" defaultValue={1} InputLabelProps={{shrink: true}} inputProps={{min: 1, style: { textAlign: 'center' }}}/> 
                </Box>
                </Grid>
                </FormControl>
                </TabPanel>
                <TabPanel value={value} index={1}>
                <FormControl fullWidth required>
                <Grid container >
                <Grid item xs = {6} >
                <Box  component="form" sx={{"& .MuiTextField-root": { m: 1, width: "100%" }, display : 'flex', flexDirection: 'column', p: 1, alignItems: 'center'}} noValidate autoComplete="off">
                <Typography ml={1}>Profiler</Typography>
                <TextField value={quotaTrialProfilerLookup} onChange={ (e) => setQuotaTrialProfilerLookup(e.target.value)} required id="outlined-number" label="Phone numbers" type="number"  defaultValue={1} InputLabelProps={{shrink: true}} inputProps={{min: 1, style: { textAlign: 'center' }}}/>
                <TextField value={quotaTrialProfilerCrawlers} onChange={ (e) => setQuotaTrialProfilerCrawlers(e.target.value)} required id="outlined-number" label="Social networks" type="number" defaultValue={1} InputLabelProps={{shrink: true}} inputProps={{min: 1, style: { textAlign: 'center' }}}/>
                <TextField value={quotaTrialProfilerCases} onChange={ (e) => setQuotaTrialProfilerCases(e.target.value)} required id="outlined-number" label="Cases" type="number" defaultValue={1} InputLabelProps={{shrink: true}} inputProps={{min: 1, style: { textAlign: 'center' }}}/>
                </Box>
                </Grid>
                <Grid item xs = {6} >
                <Box  component="form" sx={{"& .MuiTextField-root": { m: 1, width: "100%" }, display : 'flex', flexDirection: 'column', p: 1, alignItems: 'center'}} noValidate autoComplete="off">
                <Typography>WebInt</Typography>
                <TextField value={quotaTrialWebIntCrawlers} onChange={ (e) => setQuotaTrialWebIntCrawlers(e.target.value)} required id="outlined-number" label="Tasks" type="number" defaultValue={1} InputLabelProps={{shrink: true}} inputProps={{min: 1, style: { textAlign: 'center' }}}/>
                <TextField value={quotaTrialWebIntCases} onChange={ (e) => setQuotaTrialWebIntCases(e.target.value)} required id="outlined-number" label="Cases" type="number" defaultValue={1} InputLabelProps={{shrink: true}} inputProps={{min: 1, style: { textAlign: 'center' }}}/>
                <TextField value={quotaTrialWebIntMonitoring} onChange={ (e) => setQuotaTrialWebIntMonitoring(e.target.value)} required id="outlined-number" label="Monitored groups" type="number" defaultValue={1} InputLabelProps={{shrink: true}} inputProps={{min: 1, style: { textAlign: 'center' }}}/>
                </Box>
                </Grid>
                </Grid>
                <Grid item xs = {8} >
                <Box  component="form" sx={{"& .MuiTextField-root": { m: 1, width: "50%" }, display : 'flex', flexDirection: 'row-reverse', p: 0, alignItems: 'center'}} noValidate autoComplete="off">
                <TextField value={quotaTrialWebIntPeriodic} onChange={ (e) => setQuotaTrialWebIntPeriodic(e.target.value)} required id="outlined-number" label="Expired in (days):" type="number" defaultValue={1} InputLabelProps={{shrink: true}} inputProps={{min: 1, style: { textAlign: 'center' }}}/> 
                </Box>
                </Grid>
                </FormControl>
                </TabPanel>
                </Box>
                }
                {type === 'additional_quotas' &&
                <Box
                sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}
                >
                <Tabs
                  orientation="vertical"
                  variant="scrollable"
                  value={value}
                  onChange={handleChange}
                  aria-label="Vertical tabs example"
                  sx={{ borderRight: 1, borderColor: 'divider' }}
                >
                <Tab label="Quota" {...a11yProps(0)} />
                </Tabs>
                <TabPanel value={value} index={0}>
                <FormControl fullWidth required>
                <Grid container >
                <Grid item xs = {6} >
                <Box  component="form" sx={{"& .MuiTextField-root": { m: 1, width: "100%" }, display : 'flex', flexDirection: 'column', p: 1, alignItems: 'center'}} noValidate   >
                <Typography ml={1}>Profiler</Typography>
                <TextField value={additionalProfilerLookup} onChange={ (e) => setAdditionalProfilerLookup(e.target.value)} label="Phone numbers" defaultValue={1} InputLabelProps={{shrink: true}} inputProps={{min: 1, style: { textAlign: 'center' }}}/>
                <TextField value={additionalProfilerCrawlers} onChange={ (e) => setAdditionalProfilerCrawlers(e.target.value)} required id="outlined-number" label="Social networks"  defaultValue={1} InputLabelProps={{shrink: true}} inputProps={{min: 1, style: { textAlign: 'center' }}}/>
                <TextField value={additionalProfilerCases} onChange={ (e) => setAdditionalProfilerCases(e.target.value)} required id="outlined-number" label="Cases"  defaultValue={1} InputLabelProps={{shrink: true}} inputProps={{min: 1, style: { textAlign: 'center' }}}/>
                </Box>
                </Grid>
                <Grid item xs = {6} >
                <Box  component="form" sx={{"& .MuiTextField-root": { m: 1, width: "100%" }, display : 'flex', flexDirection: 'column', p: 1, alignItems: 'center'}} noValidate autoComplete="off">
                <Typography>WebInt</Typography>
                <TextField value={additionalWebIntCrawlers} onChange={ (e) => setAdditionalWebIntCrawlers(e.target.value)} required id="outlined-number" label="Tasks" defaultValue={1} InputLabelProps={{shrink: true}} inputProps={{min: 1, style: { textAlign: 'center' }}}/>
                <TextField value={additionalWebIntCases} onChange={ (e) => setAdditionalWebIntCases(e.target.value)} required id="outlined-number" label="Cases"  defaultValue={1} InputLabelProps={{shrink: true}} inputProps={{min: 1, style: { textAlign: 'center' }}}/>
                <TextField value={additionalWebIntMonitoring} onChange={ (e) => setAdditionalWebIntMonitoring(e.target.value)} required id="outlined-number" label="Monitored groups" defaultValue={1} InputLabelProps={{shrink: true}} inputProps={{min: 1, style: { textAlign: 'center' }}}/>
                </Box>
                </Grid>
                </Grid>
                <Grid item xs = {8} >
                <Box  component="form" sx={{"& .MuiTextField-root": { m: 1, width: "50%" }, display : 'flex', flexDirection: 'row-reverse', p: 0, alignItems: 'center'}} noValidate autoComplete="off">
                <TextField value={additionalWebIntPeriodic} disabled hidden={true} onChange={ (e) => setAdditionalWebIntPeriodic(e.target.value)} required id="outlined-number" label="Expired in (days):" defaultValue={1} InputLabelProps={{shrink: true}} inputProps={{min: 1, style: { textAlign: 'center' }}}/> 
                </Box>
                </Grid>
                </FormControl>
                </TabPanel>
                </Box>
                }
                {type === 'combined_phone' &&
                combinedPhones.map((el) => (
                  <Grid key={el} item xs={4}>
                    <Stack direction="row" alignItems="center">
                      <InputCheckbox
                        checked={isSourceChecked(el, sourceCombinedPhones)}
                        onChange={() => handleCombinePhoneSourceChange(el)}
                      />
                      <Box className={sprite_['social__small_' + el]} />
                      <Typography textTransform="capitalize" ml={1}>
                        {getSourceName(el)}
                      </Typography>
                    </Stack>
                  </Grid>
                ))}
                {type === 'combined_email' &&
                combinedEmails.map((el) => (
                  <Grid key={el} item xs={4}>
                    <Stack direction="row" alignItems="center">
                      <InputCheckbox
                        checked={isSourceChecked(el, sourceCombinedEmails)}
                        onChange={() => handleCombineEmailsSourceChange(el)}
                      />
                      <Box className={sprite_['social__small_' + el]} />
                      <Typography textTransform="capitalize" ml={1}>
                        {getSourceName(el)}
                      </Typography>
                    </Stack>
                  </Grid>
                ))}
                {type === 'combined_name' &&
                combinedNames.map((el) => (
                  ( el !== 'fb_api' && el !== 'instagram_api' && el !== 'x_api' && el !== 'linkedin_api' ) && ( 
                  <Grid key={el} item xs={4}>
                    <Stack direction="row" alignItems="center">
                      <InputCheckbox
                        checked={isSourceChecked(el, sourceCombinedNames)}
                        onChange={() => handleCombineNamesSourceChange(el)}
                      />
                      <Box className={sprite_['social__small_' + el]} />
                      <Typography textTransform="capitalize" ml={1}>
                        {getSourceName(el)}
                      </Typography>
                    </Stack>
                  </Grid>
                  )
                ))
                }
                {type === 'combined_id' &&
                combinedIds.map((el) => (
                  <Grid key={el} item xs={4}>
                    <Stack direction="row" alignItems="center">
                      <InputCheckbox
                        checked={isSourceChecked(el, sourceCombinedId)}
                        onChange={() => handleCombineidSourceChange(el)}
                      />
                      <Box className={sprite_['social__small_' + el]} />
                      <Typography textTransform="capitalize" ml={1}>
                        {getSourceName(el)}
                      </Typography>
                    </Stack>
                  </Grid>
                ))}
                {type === 'face_search' &&
                faceSearches.map((el) => (
                  <Grid key={el} item xs={4}>
                    <Stack direction="row" alignItems="center">
                      <InputCheckbox
                        checked={isSourceChecked(el, sourceFaceSearch)}
                        onChange={() => handleFaceSearch(el)}
                      />
                      <Box className={sprite_['social__small_' + el]} />
                      <Typography textTransform="capitalize" ml={1}>
                        {getSourceName(el)}
                      </Typography>
                    </Stack>
                  </Grid>
                ))}
                { (type === 'lookup_phone_name_verification' || type === 'real_phone') && 
                combinedPhones.map((el) => (
                  <Grid key={el} item xs={4}>
                    <Stack direction="row" alignItems="center">
                      <InputCheckbox
                        checked={isSourceChecked(el, sourceCombinedPhones)}
                        onChange={() => handleCombinePhoneSourceChange(el)}
                      />
                      <Box className={sprite_['social__small_' + el]} />
                      <Typography textTransform="capitalize" ml={1}>
                        {getSourceName(el)}
                      </Typography>
                    </Stack>
                  </Grid>
                ))}
            </Grid>
            
            {type === 'combined_name' && 
                        <Stack>
                        <Divider>
                            <Chip label="Irbis Social" size="small" style={ { marginBottom: -20} } sx={{
                              color: '#000000', 
                            }}/>
                        </Divider>
                        </Stack>
                        
              }
              <Grid container style={{ marginTop: '10px' }} >
              {type === 'combined_name' && 
              combinedNames.map((el) => (
                  ( el === 'fb_api' || el === 'instagram_api' || el === 'x_api' || el === 'linkedin_api' ) && ( 
                  <Grid key={el} item xs={4}>
                    <Stack direction="row" alignItems="center">
                      <InputCheckbox
                        checked={isSourceChecked(el, sourceCombinedNames)}
                        onChange={() => handleCombineNamesSourceChange(el)}
                      />
                      <Box className={sprite_['social__small_' + el.replace("_api", "")]} />
                      <Typography textTransform="capitalize" ml={1}>
                        {getSourceName(el)}
                      </Typography>
                    </Stack>
                  </Grid>
                  )
                ))
              }
              </Grid>
            
            {/* <FormError error={errors?.sources} /> */}
          </FormControl>

          {/** Input */}
          { type !== 'webint' && type !== 'additional_quotas' && type !== "web_data_search" && type !== "fb_person_wall_keyword" && type !== 'ipgeo_number' && 
          <FormControl fullWidth required>
            <FormLabel>{t('field.label.input')}</FormLabel>
            <OutlinedInput
              value={input}
              placeholder={t('field.hint.input')}
              onChange={(e) => setInput(e.target.value)}
            />
            <FormError error={errors?.input} />
          </FormControl>
          } 

          {/** Input for IP - Number */}
          { type === 'ipgeo_number' && 
          <FormControl fullWidth required>
            <FormLabel>{t('field.label.inputPhone')}</FormLabel>
            <OutlinedInput
              value={input}
              placeholder={t('field.hint.inputPhone')}
              onChange={(e) => setInput(e.target.value)}
            />
            <FormError error={errors?.input} />
            <FormLabel>{t('field.label.inputIP')}</FormLabel>
            <OutlinedInput
              value={inputIP}
              placeholder={t('field.hint.inputIP')}
              onChange={(e) => setInputIP(e.target.value)}
            />
            <FormError error={errors?.inputIP} />
          </FormControl>
          } 


          { (type === "web_data_search" || type === "fb_person_wall_keyword") && 
          <FormControl fullWidth required>
            <FormLabel>{t('field.label.limit')}</FormLabel>
            <OutlinedInput
              value={input}
              placeholder={t('field.hint.limit')}
              onChange={(e) => setInput(e.target.value)}
            />
            <FormError error={errors?.input} />
          </FormControl>
          } 

          {/** Button */}
          <FormControl fullWidth required>
            <FormLabel>{t('field.label.button')}</FormLabel>
            <OutlinedInput
              value={button}
              placeholder={t('field.hint.text')}
              onChange={(e) => setButton(e.target.value)}
            />
            <FormError error={errors?.button} />
          </FormControl>

          {/** Color */}
          <InputColor color={color} onChange={(color) => setColor(color.hex)} />
        </Stack>
      ) : (
        <Stack
          alignItems="center"
          mb={2}
          sx={{
            padding: 2,
            minHeight: 174,
            bgcolor: colorBackground,
            overflowY: 'auto',
          }}
        >
          <PreviewLookup data={requestData} type={type}/>
        </Stack>
      )}

      <PopupActions
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        handleNext={handleNext}
        handleSubmit={handleSubmit}
        onClose={onClose}
      />
    </Dialog>
  )
}
