import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Box,
  DialogContent,
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  Divider,
  Avatar,
  IconButton,
  OutlinedInput,
  InputAdornment,
  Link,
  Popover,
  PopoverOrigin,
  CircularProgress, useTheme,
} from '@mui/material'
import sprite_ from '../../sass/sprite.module.sass'

import { ResultTitle, useAccordionStyles } from '../../style/result'
import { SearchResult, Source } from '../../store/searcher/reducer'
import { getCorsImageUrl, RenderProfileData } from '../../component/searcher/RenderData'
import { search } from '../../function/data'
import { useDispatch } from '../../store'
import { prepareNameResult } from '../../store/searcher/service'

const searchExcludes = [
  'profile_id',
  'keyphrase',
  'keyword',
  'friendOf',
  'friendName',
  'friendProfileId',
  'friendImageUrl',
  'customrequestid',
  'type',
  'album_name',
  'setting=RABBIT_MQ_STATUS_MONITORING_QUEUE_NAME',
  'actualMainUrl',
  'profileCoverPhotoUrl',
]

function getFilterIcon(source: Source, sourceFilter: Source[]) {
  if (sourceFilter.includes(source)) return sprite_['social__large_' + source]
  else return sprite_['social__small_' + source]
}

function getAccordionStyle(idx: number, profileUrl: object) {
  return {
    cursor:
      profileUrl && Object.keys(profileUrl).length !== 0 ? 'pointer' : 'auto',
  }
}

type Props = {
  apiData: SearchResult
}

export function NameCommon(props: Props) {
  const { t } = useTranslation()
  const accordionClasses = useAccordionStyles()
  const theme = useTheme()

  const {apiData} = props
  const data = prepareNameResult(apiData)

  const initState = React.useMemo(() => {
    return data.data ? data.data.map((_) => false) : []
  }, [data.data])

  //console.log('commonNames', data)

  const [accordionOpen, setAccordionOpen] = React.useState<boolean[]>(initState)
  const [filter, setFilter] = React.useState<Source[]>([])
  const [searchValue, setSearchValue] = React.useState('')

  let filteredData: any = React.useMemo(() => {
    let filtered =
      filter.length > 0
        ? data.data?.filter((el) => filter.includes(el.from))
        : data.data

    if (searchValue.length > 0) {
      filtered = filtered?.filter((row) =>
        search(searchValue, row, searchExcludes),
      )
    }

    return filtered
  }, [data.data, filter, searchValue])

  const sources = React.useMemo(() => {
    return [...new Set(data.data?.map((el) => el.from))] || []
  }, [data.data])

  const handleChangeAccordion =
    (idx: number) => (event: React.SyntheticEvent, expanded: boolean) => {
      setAccordionOpen((state) => {
        const newState = [...state]
        newState[idx] = expanded
        return newState
      })
    }

  filteredData = filteredData?.filter((el: any) => !!el?.profileData)
  //console.log('filtered data', filteredData)

  const dispatch = useDispatch()

  return (
    <>
      <Stack>
        {
          filteredData && filteredData?.length > 0 && <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            lineHeight={1}
            mb={3}
          >
            <ResultTitle>Details:</ResultTitle>
            <OutlinedInput
              value={searchValue}
              sx={{
                background: 'none',
              }}
              placeholder={t('searcher.name.popup.searchPlaceholder')}
              onChange={(e) => setSearchValue(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <Box className={sprite_.common__search} />
                </InputAdornment>
              }
              style={{ marginLeft: 'auto', width: 192, height: 38 }}
            />
          </Stack>
        }
        <>
          {filteredData.map((el: any, idx: number) => {
            return (
              <Accordion
                key={idx}
                classes={accordionClasses}
                square
                expanded={
                  el.profileData && Object.keys(el.profileData).length !== 0
                    ? accordionOpen[idx]
                    : false
                }
                onChange={handleChangeAccordion(idx)}
              >
                <AccordionSummary
                  style={getAccordionStyle(idx, el.profileData)}
                  sx={{
                    [theme.breakpoints.only("xs")]: {
                      justifyContent: 'flex-start',
                      margin: '3px',
                    },
                    margin: '10px',
                  }}
                >
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={3}
                    width="100%"
                  >
                    <Avatar
                      src={getCorsImageUrl(el.profileImageUrl)}
                      sx={{
                        height: 75,
                        width: 75,
                        [theme.breakpoints.only("xs")]: {
                          width: 45,
                          height: 45,
                        },
                      }}
                    />
                    <Stack>
                      <Typography variant="bold">{el.profileName}</Typography>
                      <Typography variant="semiBold" color="secondary" sx={{
                        [theme.breakpoints.only("xs")]: {
                          fontSize: '10px',
                        },
                      }}>
                        {el.profileData && el.profileData['Profile Headline']}
                      </Typography>
                      <Typography variant="bold" color="secondary">
                        {el.profileCity}
                      </Typography>
                    </Stack>
                    <Stack
                      justifyContent="space-between"
                      alignItems="flex-end"
                      style={{ marginLeft: 'auto', height: '100%' }}
                      sx={{
                        [theme.breakpoints.only("xs")]: {
                          flexDirection: 'row',
                          width: '60px!important',
                          overflow: 'hidden',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                          flexShrink: '0',
                        },
                      }}
                    >
                      <Box className={sprite_['social__small_' + el.from]} sx={{
                        [theme.breakpoints.only("xs")]: {
                          marginRight: '3px',
                        },
                      }} />
                      {el.profileUrl && (
                        <Link
                          variant="underlined"
                          href={el.profileUrl}
                          target="_blank"
                          sx={{
                            [theme.breakpoints.only("xs")]: {
                              fontSize: '10px',
                            },
                          }}
                        >
                          @{el.profile_id}
                        </Link>
                      )}
                    </Stack>
                  </Stack>
                </AccordionSummary>
                <AccordionDetails>
                  {el.profileData && (
                    <RenderProfileData data={el.profileData} fullData={el} />
                  )}
                </AccordionDetails>
              </Accordion>
            )
          })}
        </>
      </Stack>
    </>
  )
}
