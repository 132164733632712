import React from "react"
import { useTranslation } from "react-i18next"
import { t } from 'i18next'
import {
  Accordion, AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  LinearProgress,
  Stack,
  TableCell,
  TableRow, Tooltip,
  Typography,
  Button,
  Avatar,
} from '@mui/material'
import { useTableStyles } from '../datatable/hook/useStyles'
import { DataTable } from "../datatable"
// import { TableStatus } from '../datatable/component'
import { useSubscriber } from "../../hook/useSubscriber"
import dashboard_ from "../../sass/dashboard.module.sass"
import table_ from '../../sass/table.module.sass'
import { SvgIcon } from "../SvgIcon"
import { FailedSubscriberRequest, Usage } from "../../store/dashboard/reducer"
import { Subscriber } from "../../store/user/reducer"
import { HeaderData, TableRef } from '../datatable/types'
import { formatDate, getNow } from '../../function/date'
import { SearchResult, Type } from '../../store/searcher/reducer'
import { useInProgressStyles } from '../../style/progress'
import { useTransparentAccordionStyles } from '../../style/accordion'
import sprite_ from '../../sass/sprite.module.sass'

interface Props {
  data: Subscriber
}

const tableLimit = 20

function getSearchType(type: Type) {
  switch (type) {
    case 'phone':
      return t('searcher.type.phone')
    case 'name':
      return t('searcher.type.name')
    case 'post':
      return t('searcher.type.post')
    case 'deepweb':
      return t('searcher.type.deepweb')
    case 'combined_phone':
      return t('searcher.type.combined_phone')
    case 'combined_email':
      return t('searcher.type.combined_email')
    case 'combined_name':
      return t('searcher.type.combined_name')
    case 'combined_id':
      return t('searcher.type.combined_id')
    case 'face_search':
      return t('searcher.type.face_search')
    case 'irbis_eye':
      return t('searcher.type.irbis_eye')
    case 'sentiment_analysys':
      return t('searcher.type.sentiment_analysys')
    case 'web_data_search':
      return t('searcher.type.web_data_search')
    case 'kyc':
      return t('searcher.type.kyc')
    case 'web_collection':
      return t('searcher.type.web_collection')
    case 'ip_geo':
      return t('searcher.type.ip_geo')
    case 'name_by_country':
      return t('searcher.type.name_by_country')
    case 'photo_searcher':
        return t('searcher.type.sniper_api')
    case 'ipgeo_number':
        return t('searcher.type.ipgeo_number')
    case 'fb_person_wall_keyword':
      return t('searcher.type.fb_person_wall_keyword')
    case 'psycho_profile' : 
      return t('searcher.type.psycho_profile')
    case 'psycho_profile_summary' : 
      return t('searcher.type.psycho_profile_summary')
    case 'api_v2_x': 
      return t('searcher.type.api_v2_x')
    case 'api_v2_instagram' : 
      return t('searcher.type.api_v2_instagram')
    case 'api_v2_linkedin' :
      return t('searcher.type.api_v2_linkedin')
  }
}

function isTelegramBotImage(base64 : string) {
  if (!base64.includes('data:image/jpeg;base64')) {
    base64 = 'data:image/jpeg;base64' + base64 
  }
  return base64
}

export function SubscriberRequests({ data }: Props) {
  //call hooks
  const { t } = useTranslation()
  const tableClasses = useTableStyles()
  const progressInClasses = useInProgressStyles()
  const transparentAccordion = useTransparentAccordionStyles()
  const tableRef = React.useRef<TableRef>(null)

  //handlers
  const handleRowClick = (row: SearchResult) => {
    window.open(`/result/${row.id}`, '_blank', 'noopener,noreferrer')
    return false
  }
  function handleDownload(e: React.MouseEvent, row: SearchResult) {
    e.stopPropagation()

    const json = JSON.stringify(row)
    const blob = new Blob([json], { type: 'application/json' })
    const href = URL.createObjectURL(blob)
    const link = document.createElement('a')
    const sources = row.sources.map((el) => el.name)
    link.href = href
    link.download = `${row.criteria}_${
      sources.toString() || ''
    }_${getNow()}.json`
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  //helpers
  const tableHeaderData: HeaderData[] = [
    { key: 'criteria', text: t('searcher.table.criteria') },
    { key: 'type', text: t('searcher.table.type') },
    {
      key: 'date',
      text: t('searcher.table.date'),
      sortable: true,
      defaultSort: true,
      className: 'wideVisible',
    },
    {
      key: 'status',
      text: t('searcher.table.status'),
      style: { minWidth: 210 },
      className: 'wideVisible',
    },
    {
      key: 'sources',
      text: t('searcher.table.sources'),
      style: { width: 100 },
      className: 'wideVisible',
    },
    {
      key: 'isDeleted',
      text: 'Request label',
      style: { width: 100 },
      className: 'wideVisible',
    },
    {
      key: 'actions',
      text: t('searcher.table.actions'),
      style: { width: 110 },
    },
  ]
  const RenderTableBody = (row: SearchResult, idx: number) => {
    return (
      <TableRow key={row.id} className={idx % 2 !== 0 ? tableClasses.rowEven : ""} onClick={() => handleRowClick(row)} style={{cursor: 'pointer'}}>
      { (row.type === 'face_search' || row.type === 'irbis_eye' || row.type === 'photo_searcher' ? (
        <TableCell>
          <Avatar alt="Remy Sharp" src={row.criteria} />
        </TableCell>
      ) : (
        row.type === 'sentiment_analysys' && row.criteria.length > 25 ?
          <TableCell>{row.criteria.substr(0,25) + "..."}</TableCell> :
          <TableCell style={{overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100px'}}>{row.criteria}</TableCell>
      ))
      }
        <TableCell>{getSearchType(row.type)}</TableCell>
        <TableCell className="wideVisible">{formatDate(row.createdTime, 'dd/MM/yyyy HH:mm:ss')}</TableCell>
        <TableCell className="wideVisible">
          {row.status === 'progress' && row.percentage ? (
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={1}
            >
              <Typography noWrap>{t('searcher.status.progress')}</Typography>
              <Box width="60%">
                <LinearProgress
                  variant="determinate"
                  color="success"
                  value={Number(row.percentage)}
                />
              </Box>
            </Stack>
          ) : row.status === 'finished' ? (
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={1}
            >
              <Typography noWrap>{t('searcher.status.finished')}</Typography>
              <Box width="60%">
                <LinearProgress
                  variant="determinate"
                  color="success"
                  value={100}
                />
              </Box>
            </Stack>
          ) : row.status === 'failed' ? (
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={1}
            >
              <Typography noWrap>Failed</Typography>
              <Box width="60%">
                <LinearProgress variant="determinate" value={0} />
              </Box>
            </Stack>
          ) : row.status === 'empty' ? (
            <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
          >
            <Typography noWrap>{t('searcher.status.empty')}</Typography>
            <Box width="60%">
              <LinearProgress
                variant="determinate"
                color="success"
                value={100}
              />
            </Box>
          </Stack>
          ) : (
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={1}
            >
              <Typography noWrap>{t('searcher.status.progress')}</Typography>
              <Box width="60%">
                <LinearProgress
                  classes={progressInClasses}
                  variant="determinate"
                  value={100}
                />
              </Box>
            </Stack>
          )}
        </TableCell>
        <TableCell className="wideVisible">
          <Accordion
            classes={transparentAccordion}
            //onClick={(e) => handleSourceClick(e, row)}
            expanded={
              row.status === 'progress' && row.percentage ? undefined : false
            }
            disabled={row.status === 'finished'}
          >
            <AccordionSummary>
              <Stack direction="row" spacing={1} width={'200px'} flexWrap={'wrap'} gap={'10px'}>
                { row.type !== 'api_v2_x' && row.type !== 'api_v2_instagram' && row.type !== 'api_v2_linkedin' ? 
                row.sources.map((el, idx) => (
                  <Box
                    key={idx}
                    className={sprite_['social__small_' + el.name]}
                    marginLeft={'0!important'}
                  />
                )) : 
                row.sources.map((el, idx) => (
                  <Box> {el.name} </Box>
                ))
                }
              </Stack>
            </AccordionSummary>
            <AccordionDetails>
              {row.sources.map((el, idx) => (
                <Stack
                  key={idx}
                  direction="row"
                  alignItems="center"
                  spacing={1}
                >
                  <Box className={sprite_['social__small_' + el.name]} />
                  <Box width="60%">
                    <LinearProgress
                      variant="determinate"
                      color="success"
                      value={Number(el.percentage) || 0}
                    />
                  </Box>
                </Stack>
              ))}
            </AccordionDetails>
          </Accordion>
        </TableCell>

        <TableCell>
        {row.isDeleted && (
          <Box className={table_.status_deleted}>DELETED</Box>
        )}
        </TableCell>

        <TableCell>
          <Stack direction="row" spacing={1}>
            <Tooltip title={<span>{t('common.download')}</span>}>
              <IconButton onClick={(e) => handleDownload(e, row)} size="small">
                <Box className={sprite_.common__download} />
              </IconButton>
            </Tooltip>
          </Stack>
        </TableCell>
      </TableRow>
    )
  }

  return (
    <>
      <Typography variant="semiBold" fontSize={18}>
        {t("dashboard.subscriberRequests")}
      </Typography>

      <Box className={dashboard_.card_column} mt={1}>
        <DataTable
          ref={tableRef}
          id="searcher"
          url={`/request-monitor/userwithdeleted/${data.uid}`}
          className={tableClasses.card}
          headerData={tableHeaderData}
          renderBody={RenderTableBody}
          title={t('searcher.table.title')}
          limit={tableLimit}
          pagination
          // refreshPerSeconds={10}
          selectable={false}
          onRowClick={handleRowClick}
          emptyText={
            <Typography variant="light" width={380} fontSize={18} align="center">
              {t('searcher.table.emptyText')}
            </Typography>
          }
          loadingIndication={true}
        />
      </Box>
    </>
  )
}
